import { DataSchema, schemaWithTitle } from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { ConfigCard } from 'components/ConfigCard'
import { useGenericConfig } from './contexts/generic-config-context'

export function DataCollectionMethodParametersConfig({
  methodKey,
}: {
  methodKey: string
}) {
  const { dataCollectionSpec, patchConfig, config, variablesSchema } =
    useGenericConfig()

  const parametersSchema: DataSchema =
    dataCollectionSpec?.[methodKey]?.parametersSchema

  if (!parametersSchema) return null

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Parameters</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <DataBuilderForm
          field={makeDataField({
            schema: schemaWithTitle(parametersSchema, 'Parameters'),
            value: config.parameters,
            variablesSchema,
          })}
          onChange={(parameters) => patchConfig({ parameters })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
