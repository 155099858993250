import { DataLinkTable } from '@integration-app/sdk'
import useIntegrationElement from 'components/IntegrationElements/hooks/useIntegrationElement'
import CreateElementPopup from 'routes/Workspaces/Workspace/components/CreateElementPopup'

const ELEMENT_NAME = 'Data Link Table'

export function PopupCreateDataLinkForm({
  open,
  onClose,
  onCreate,
}: {
  open: boolean
  onClose: () => void
  onCreate: (item: DataLinkTable) => void
}) {
  const { createItem } = useIntegrationElement<DataLinkTable>({
    route: 'data-link-tables',
  })

  if (!open) return null

  return (
    <CreateElementPopup
      elementName={ELEMENT_NAME}
      onClose={onClose}
      onCreate={async (data: Partial<DataLinkTable>) => {
        const createdDataLinkTable = await createItem(data)
        onClose()
        onCreate(createdDataLinkTable)
      }}
      isCreateDataValid={(data) => {
        return !!data.name && !!data.key
      }}
      defaultCreateData={{
        key: '',
        name: '',
      }}
    />
  )
}
