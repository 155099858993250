export function stringifyWithIndent(obj: unknown, indent = 2) {
  const lines = JSON.stringify(obj, null, 2).split('\n')

  return lines
    .map((line, i) => {
      if (i === 0) return line

      return ' '.repeat(indent) + line
    })
    .join('\n')
}
