import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { NodeConfigurationErrorsGetter } from '../configuration-errors/types'
import { getBrokenVariablesConfigurationErrors } from '../configuration-errors/getBrokenVariablesConfigurationErrors'
import useFlowNode from '../../../../../../../../../../components/FlowBuilder/flow-node-context'
import { ForEachV2 } from './ForEachV2'

export function ForEach() {
  const { node } = useFlowNode()
  const { patchConfig, config, variablesSchema } = useGenericConfig()

  if (node.version == 2) {
    return <ForEachV2 />
  }

  return (
    <div>
      <p>
        Please select or construct a list to iterate over. Following nodes will
        be executed for each item in this list.
      </p>

      <DataBuilderForm
        onChange={(list) => patchConfig({ list })}
        field={makeDataField({
          schema: { type: 'array', title: 'List' },
          value: config.list,
          variablesSchema,
        })}
      />
    </div>
  )
}

export const getForEachConfigErrors: NodeConfigurationErrorsGetter = ({
  config,
  runTimeVariablesSchema,
}) => {
  const errors = []

  errors.push(
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type 'NodeConfigurationErrorData' is n... Remove this comment to see the full error message
    ...getBrokenVariablesConfigurationErrors(config.list, [
      runTimeVariablesSchema,
    ]),
  )

  return errors
}
