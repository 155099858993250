import { SchemaBuilder } from '@integration-app/ui'
import { VStack } from 'styled-system/jsx'
import { Heading } from 'ui-kit/heading'
import { Text } from 'ui-kit/text'
import { useGenericConfig } from './contexts/generic-config-context'

export function HttpResponseConfig() {
  const { config, patchConfig } = useGenericConfig()

  return (
    <VStack gap={2} alignItems={'stretch'}>
      <Heading as={'h3'} m={0} size={'lg'}>
        Response Schema
      </Heading>

      <Text m={0}>What to expect from this request</Text>

      <SchemaBuilder
        schema={config.responseSchema}
        isObject={false}
        onChange={(responseSchema) => patchConfig({ responseSchema })}
      />
    </VStack>
  )
}
