import { NodeProps } from 'reactflow'
import { TargetHandle } from 'components/FlowBuilder/Graph/elements/Handle/Handle'
import { PlaceholderNodeBody } from './PaceholderNodeBody/PlaceholderNodeBody'
import { AddNodeButton } from './AddNodeButton'
import { NodeBodyContainer } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBodyContainer'
import { NodeStats } from '../RegularNode/RegularNode'

export interface RegularNodePlaceholderNodeData {
  parent?: string
  subgraphParent?: string
}

export function RegularNodePlaceholderNode(
  props: NodeProps<RegularNodePlaceholderNodeData>,
) {
  const { parent, subgraphParent } = props.data

  return (
    <NodeBodyContainer>
      <TargetHandle />

      <PlaceholderNodeBody>
        <AddNodeButton
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          parent={parent}
          subgraphParent={subgraphParent}
          child={''}
          linkIndex={0}
        />
      </PlaceholderNodeBody>
      <NodeStats props={props} />
    </NodeBodyContainer>
  )
}
