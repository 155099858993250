import { ValueChangeDetails } from '@zag-js/select'
import React from 'react'
import { TbSelector } from 'react-icons/tb'
import { createListCollection, Select as SelectUiKit } from 'ui-kit/select'
import { Item, LoadingItem, ValueText } from './chunks'
import { SelectItemType, SelectType } from './types'

export function Select({
  label,
  placeholder,
  items,
  value,
  onValueChange,
  loading,
  positioning = {
    hideWhenDetached: true,
    sameWidth: true,
    strategy: 'fixed',
  },
  ItemTextComponent,
  ...props
}: SelectType) {
  const collection = createListCollection({ items })
  const selectedValue = value ? [value.value] : undefined

  function handleValueChange({ items }: ValueChangeDetails<SelectItemType>) {
    onValueChange?.(items?.[0])
  }

  return (
    <SelectUiKit.Root
      collection={collection}
      value={selectedValue}
      onValueChange={handleValueChange}
      positioning={positioning}
      {...props}
    >
      {!!label && <SelectUiKit.Label>{label}</SelectUiKit.Label>}
      <SelectUiKit.Control>
        <SelectUiKit.Trigger>
          <ValueText
            ItemTextComponent={ItemTextComponent}
            value={value}
            placeholder={placeholder}
          />
          <TbSelector />
        </SelectUiKit.Trigger>
      </SelectUiKit.Control>
      <SelectUiKit.Positioner>
        <SelectUiKit.Content>
          <SelectUiKit.ItemGroup>
            {loading && <LoadingItem />}
            {collection.items.map((item) => (
              <Item
                ItemTextComponent={ItemTextComponent}
                item={item}
                key={item.value}
              />
            ))}
          </SelectUiKit.ItemGroup>
        </SelectUiKit.Content>
      </SelectUiKit.Positioner>
    </SelectUiKit.Root>
  )
}
