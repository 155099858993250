import { Dimensions, Edge, Node } from 'reactflow'
import { RegularNodeData } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/Graph/elements/RegularNode/RegularNode'
import { calculateNodeStackItems } from './NodeBody/NodeStackItems/calculate'
import { TriggerNodeData } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/Graph/elements/TriggerNode/TriggerNode'
import {
  getDownstreamNodesGraphHeight,
  getSubFlowRootNode,
  getDownstreamNodesGraphWidth,
  isNodeWithSubFlow,
} from '../new-layout'

interface GraphNodeSpec {
  getSize(node: Node, nodes: Node[], edges: Edge[]): Dimensions
}

// TODO: better names for types
export enum GraphEdgeType {
  Edge = 'Edge',

  EdgeToPortal = 'EdgeToPortal',
  EdgeToNodePlaceholder = 'EdgeToNodePlaceholder',
  EdgeToNodeOrLinkPlaceholder = 'EdgeToNodeOrLinkPlaceholder',
}

export enum GraphNodeType {
  Root = 'Root',

  Node = 'Node',
  Trigger = 'Trigger', // TODO: there is not point in having separate type for Trigger, you can remove it

  Portal = 'Portal',

  TriggerPlaceholder = 'TriggerPlaceholder',
  NodePlaceholder = 'NodePlaceholder',
  NodeOrLinkPlaceholder = 'NodeOrLinkPlaceholder',
}

export const DEFAULT_NODE_WIDTH = 265
export const DEFAULT_NODE_HEIGHT = 52

export const NODE_STACK_ITEM_HEIGHT = 25

export const DEFAULT_NODE_SIZE: Dimensions = {
  width: DEFAULT_NODE_WIDTH,
  height: DEFAULT_NODE_HEIGHT,
}

export const SUB_FLOW_PADDING = 100

export const GRAPH_NODE_SPECS: Record<GraphNodeType, GraphNodeSpec> = {
  [GraphNodeType.Root]: {
    getSize(): Dimensions {
      return { width: 1, height: 1 }
    },
  },

  [GraphNodeType.Node]: {
    getSize(
      node: Node<RegularNodeData>,
      nodes: Node[],
      edges: Edge[],
    ): Dimensions {
      const width = DEFAULT_NODE_WIDTH
      const height =
        DEFAULT_NODE_HEIGHT +
        NODE_STACK_ITEM_HEIGHT *
          calculateNodeStackItems(node.data.flowNode).length

      if (isNodeWithSubFlow(node)) {
        const rootNode = getSubFlowRootNode(node, nodes)

        if (!rootNode) {
          return {
            height: height + SUB_FLOW_PADDING * 2,
            width: width + SUB_FLOW_PADDING * 2,
          }
        }

        const subFlowWidth = getDownstreamNodesGraphWidth(
          rootNode,
          nodes,
          edges,
        )
        const subFlowHeight = getDownstreamNodesGraphHeight(
          rootNode,
          nodes,
          edges,
        )

        return {
          width: subFlowWidth + SUB_FLOW_PADDING * 2,
          height: subFlowHeight + SUB_FLOW_PADDING * 2,
        }
      }

      return { width, height }
    },
  },

  [GraphNodeType.Trigger]: {
    getSize(node: Node<TriggerNodeData>): Dimensions {
      const stackItems = calculateNodeStackItems(node.data.flowNode)

      const height =
        DEFAULT_NODE_HEIGHT + NODE_STACK_ITEM_HEIGHT * stackItems.length

      return { width: DEFAULT_NODE_WIDTH, height }
    },
  },

  [GraphNodeType.Portal]: {
    getSize(): Dimensions {
      return {
        width: DEFAULT_NODE_WIDTH,
        height: DEFAULT_NODE_HEIGHT + NODE_STACK_ITEM_HEIGHT,
      }
    },
  },

  [GraphNodeType.TriggerPlaceholder]: {
    getSize(): Dimensions {
      return DEFAULT_NODE_SIZE
    },
  },

  [GraphNodeType.NodePlaceholder]: {
    getSize(): Dimensions {
      return DEFAULT_NODE_SIZE
    },
  },

  [GraphNodeType.NodeOrLinkPlaceholder]: {
    getSize(): Dimensions {
      return DEFAULT_NODE_SIZE
    },
  },
}
