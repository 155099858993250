import { SvgIconType } from '@integration-app/ui/SvgIcon'
import { useFlowBuilder } from 'routes/Workspaces/Workspace/components/FlowBuilder/flow-builder-context'
import { CommonRoundedButton } from 'components/FlowBuilder/Graph/elements/NodeBody/CommonRoundedButton'
import classes from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBody.module.css'

export function DeleteNodeButton({ nodeKey }) {
  const { toggleDeleteNode } = useFlowBuilder()

  function handleDelete() {
    toggleDeleteNode(nodeKey)
  }

  return (
    <CommonRoundedButton
      onClick={handleDelete}
      iconType={SvgIconType.Cancel}
      className={classes.commonRoundedButtonTopRight}
    />
  )
}
