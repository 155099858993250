import { useState, HTMLAttributes } from 'react'
import { sva } from 'styled-system/css'
import * as StyledSwitch from 'ui-kit/styled/switch'
import { SwitchProps } from 'ui-kit/switch'
import clsx from 'utils/clsx'

const recipe = sva({
  className: 'configCard',
  slots: ['label', 'labelLeft', 'labelRight', 'srOnly'],
  base: {
    label: {
      color: 'fg.muted',
      _hover: {
        color: 'fg.default',
      },
    },
    labelLeft: {
      width: 3,
      color: 'fg.default',
      fontWeight: 'bold',
      _checked: {
        color: 'initial',
        fontWeight: 'initial',
      },
    },
    labelRight: {
      width: 9,
      _checked: {
        color: 'fg.default',
        fontWeight: 'bold',
      },
    },
    srOnly: { srOnly: true },
  },
})

interface CodeModeSwitchProps {
  codeMode: boolean
  onToggle: (checked: boolean) => void
}

export function CodeModeSwitch({
  codeMode,
  onToggle,
  className,
  ...props
}: SwitchProps & CodeModeSwitchProps) {
  const classes = recipe()
  return (
    <StyledSwitch.Root
      size={'sm'}
      checked={codeMode}
      onCheckedChange={({ checked }) => onToggle(checked)}
      className={className}
      {...props}
    >
      <CodeModeSwitchLabel className={classes.labelLeft}>
        UI
      </CodeModeSwitchLabel>
      <StyledSwitch.Control>
        <StyledSwitch.Thumb />
      </StyledSwitch.Control>
      <CodeModeSwitchLabel className={classes.labelRight}>
        Code
      </CodeModeSwitchLabel>
      <StyledSwitch.HiddenInput aria-labelledby={undefined} />
    </StyledSwitch.Root>
  )
}

/*
 * We use two `StyledSwitch.Label` and `StyledSwitch.HiddenInput` is labeled by it. We can't override it.
 * To make it accessible we need to provide a meaningful label for screen readers and hide it visually.
 * */
function CodeModeSwitchLabel({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLSpanElement>) {
  const classes = recipe()
  return (
    <StyledSwitch.Label className={clsx(classes.label, className)} {...props}>
      <span className={classes.srOnly}>Switch between UI and Code mode</span>
      <span aria-hidden={true}>{children}</span>
    </StyledSwitch.Label>
  )
}

export function useCodeMode() {
  const [codeMode, setCodeMode] = useState(false)

  function toggleCodeMode() {
    setCodeMode(!codeMode)
  }

  return {
    codeMode,
    toggleCodeMode,
  }
}
