import { useEffect, useState } from 'react'
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch'
import { useRouter } from 'next/router'
import {
  AlgoliaPopup,
  AlgoliaPopupSearchWrapper,
  AlgoliaSearchResult,
  AlgoliaSidebarSearchPlaceholder,
} from 'routes/Docs/components/DocsSearch/AlgoliaSearch'
import { useWindowEvent } from '@integration-app/ui/hooks/useWindowEvent'
import { searchClient } from 'utils/algolia'

/*
 * Algolia search styling is in globals.css file, because we can't redefine classNames
 */
export function DocsSearchInput() {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  useWindowEvent('keydown', (event) => {
    if (
      event.key === 'k' &&
      (event.metaKey || event.ctrlKey) &&
      !isSearchOpen
    ) {
      openPopup()
    }
    if (event.key === 'Escape' && isSearchOpen) {
      closePopup()
    }
  })

  const router = useRouter()

  const documentBodyClassList = document.body.classList

  const searchHook = (query: string, search: (query: string) => void) => {
    search(query)
  }

  const openPopup = () => {
    documentBodyClassList.add('overflow-hidden')
    setIsSearchOpen(true)
  }
  const closePopup = () => {
    documentBodyClassList.remove('overflow-hidden')
    setIsSearchOpen(false)
  }

  useEffect(() => {
    closePopup() // close popup on route change
  }, [router.asPath])

  if (!searchClient) {
    return <p>Search is not configured</p>
  }

  return (
    <InstantSearch searchClient={searchClient} indexName='docs'>
      <AlgoliaSidebarSearchPlaceholder onClick={openPopup} />

      <AlgoliaPopup open={isSearchOpen} onClose={closePopup}>
        <AlgoliaPopup.Header>
          <AlgoliaPopupSearchWrapper>
            <SearchBox
              queryHook={searchHook}
              placeholder='Search'
              autoFocus={true}
            />
          </AlgoliaPopupSearchWrapper>
        </AlgoliaPopup.Header>

        <AlgoliaPopup.Body>
          <Hits hitComponent={AlgoliaSearchResult} />
        </AlgoliaPopup.Body>
      </AlgoliaPopup>
    </InstantSearch>
  )
}
