import NextRouter from 'next/router'
import { useRouter } from 'next/router'
import { useLocation, Router } from 'react-router-dom'
import { StaticRouter } from 'react-router-dom/server'
import { PropsWithChildren, useReducer, useRef } from 'react'
import { Update } from 'history'
import { parsePath, createMemoryHistory, MemoryHistory, To } from 'history'

/*
 * Use this component to wrap pages where you want to have React Router v6 routing.
 * Make sure page path is a catch-all route (e.g. /path/to/your/page/[[...index]].tsx).
 */
export function SPA({ children }) {
  const { asPath, query } = useRouter()

  // making sure router path resolved
  if (asPath.includes('[[...index]]')) {
    return null
  }

  const queryIndex = (query.index || []) as string[]

  const basename = asPath
    .split('#')[0] // remove anchor
    .split('?')[0] // remove query
    .replace(queryIndex.join('/'), '') // remove catch-all path
    .replace(/\/$/, '') // remove trilling slash

  return (
    <CustomRouter asPath={asPath} basename={basename}>
      {children}
    </CustomRouter>
  )
}

function CustomRouter({
  children,
  basename,
  asPath,
}: PropsWithChildren<{
  asPath: string
  basename: string
}>) {
  if (typeof window === 'undefined') {
    return <StaticRouter location={asPath}>{children}</StaticRouter>
  }

  const historyRef = useRef<MemoryHistoryInstance>()

  if (historyRef.current == null) {
    historyRef.current = createNextHistory(asPath)
    historyRef.current.listen((update) => dispatch(update))
  }

  const history = historyRef.current

  const [state, dispatch] = useReducer((_: Update, action: Update) => action, {
    action: history.action,
    location: history.location,
  })

  return (
    <Router location={state.location} navigator={history} basename={basename}>
      {children}
    </Router>
  )
}

type MemoryHistoryInstance = MemoryHistory<{
  shallow?: boolean
  locale?: string | false
}>

function fromReactRouterToNextUrl(to: To) {
  const path = typeof to === 'string' ? parsePath(to) : to

  return {
    hash: path.hash,
    pathname: path.pathname,
    search: path.search,
  }
}

// NOTE: custom implementations for enhanced history object is designed to work with Next.js router.
// Main purpose is to keep Next.js router in sync with React Router v6 history object.
function createNextHistory(asPath: string): MemoryHistoryInstance {
  const historyMemory = createMemoryHistory({
    initialEntries: [asPath],
  }) as MemoryHistoryInstance

  const enhancedHistory: MemoryHistoryInstance = {
    get index() {
      return historyMemory.index
    },
    get action() {
      return historyMemory.action
    },
    get location() {
      return historyMemory.location
    },
    createHref: historyMemory.createHref,
    push(to, state) {
      const path = fromReactRouterToNextUrl(to)

      const isSameUrl =
        path.pathname === historyMemory.location.pathname &&
        path.search === historyMemory.location.search &&
        path.hash === historyMemory.location.hash

      if (!isSameUrl) {
        historyMemory.push(to, state)
        void NextRouter.push(path, void 0, state as any)
      }
    },
    replace(to, state) {
      const path = fromReactRouterToNextUrl(to)
      historyMemory.replace(to, state)
      void NextRouter.replace(path, void 0, state as any)
    },
    go(_delta) {
      throw new Error(`history.go isn't supported`)
    },
    back() {
      historyMemory.go(-1)
      NextRouter.back()
    },
    forward() {
      throw new Error(`history.forward isn't supported`)
    },
    listen(listener) {
      function handleRouteChange(_url: string) {
        listener({
          action: historyMemory.action,
          location: historyMemory.location,
        })
      }
      NextRouter.events.on('routeChangeComplete', handleRouteChange)
      NextRouter.events.on('hashChangeComplete', handleRouteChange)

      return () => {
        NextRouter.events.off('routeChangeComplete', handleRouteChange)
        NextRouter.events.off('hashChangeComplete', handleRouteChange)
      }
    },
    block(_blocker) {
      throw new Error(`history.block isn't supported`)
    },
  }

  function handleRouteChangeFromNext(url: string) {
    historyMemory.push(parsePath(url), { locale: NextRouter.locale })
  }

  NextRouter.events.on('routeChangeComplete', handleRouteChangeFromNext)
  NextRouter.events.on('hashChangeComplete', handleRouteChangeFromNext)

  return enhancedHistory
}

export function useIsInRoute() {
  const { pathname } = useLocation()

  const paths = pathname.split('/')

  return (...routes: string[]) => routes.every((p) => paths.includes(p))
}
