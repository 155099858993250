import React from 'react'
import { TbCheck } from 'react-icons/tb'
import { Select as SelectUiKit } from 'ui-kit/select'
import {
  SelectItemComponentType,
  SelectItemType,
  ValueTextComponentType,
} from './types'

export function ValueText({
  value,
  placeholder,
  ItemTextComponent,
}: ValueTextComponentType) {
  if (ItemTextComponent && value) {
    return (
      <SelectUiKit.ValueText asChild>
        <ItemTextComponent item={value} />
      </SelectUiKit.ValueText>
    )
  }
  return (
    <SelectUiKit.ValueText placeholder={placeholder}>
      {value?.label}
    </SelectUiKit.ValueText>
  )
}

export function Item({ item, ItemTextComponent }: SelectItemComponentType) {
  const TextComponent = ItemTextComponent || DefaultItemText
  return (
    <SelectUiKit.Item item={item}>
      <SelectUiKit.ItemText asChild={!!ItemTextComponent}>
        <TextComponent item={item} />
      </SelectUiKit.ItemText>
      <SelectUiKit.ItemIndicator>
        <TbCheck />
      </SelectUiKit.ItemIndicator>
    </SelectUiKit.Item>
  )
}

function DefaultItemText({ item }: { item: SelectItemType }) {
  return <>{item.label}</>
}

export function LoadingItem() {
  return (
    <SelectUiKit.Item item={{ disabled: true }}>Loading...</SelectUiKit.Item>
  )
}
