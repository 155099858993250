import { PropsWithChildren } from 'react'
import clsx from 'utils/clsx'
import classes from './ToDoSection.module.css'

export function ToDoSection({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx(classes.section, className)}>{children}</div>
}
