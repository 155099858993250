import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { v4 as uuid } from 'uuid'

interface DisclosureSectionContextInterface {
  registerId: (id: string) => void
  toggleStatus: (id: string) => void
  isOpenedId: (id: string) => boolean
}

const DisclosureSectionContext =
  createContext<DisclosureSectionContextInterface>({
    registerId: () => {},
    toggleStatus: () => {},
    isOpenedId: () => false,
  })
DisclosureSectionContext.displayName = 'DisclosureSectionContext'

export function DisclosureSectionProvider({
  children,
  accordion,
  open,
}: PropsWithChildren<{ open?: boolean; accordion?: boolean }>) {
  const [openFirstContent, setOpenFirstContent] = useState(!!open)
  const [ids, setIds] = useState<string[]>([])
  const [openedIds, setOpenedIds] = useState<string[]>([])

  function registerId(id: string) {
    openFirstContent &&
      setOpenedIds((ids) => {
        if (ids.length === 0) {
          return [id]
        }

        return ids
      })

    setOpenFirstContent(false)

    setIds([...ids, id])
  }

  const toggleStatus = (id: string) => {
    setOpenFirstContent(false)
    if (accordion) {
      if (!openedIds.includes(id)) {
        setOpenedIds([id])
      }
    } else {
      if (openedIds.includes(id)) {
        setOpenedIds(openedIds.filter((openedId) => openedId !== id))
      } else {
        setOpenedIds([...openedIds, id])
      }
    }
  }

  function isOpenedId(id: string) {
    return openedIds.includes(id)
  }

  const value = {
    registerId,
    toggleStatus,
    isOpenedId,
  }

  return (
    <DisclosureSectionContext.Provider value={value}>
      {children}
    </DisclosureSectionContext.Provider>
  )
}

function useDisclosureSection() {
  return useContext(DisclosureSectionContext)
}

export function useDisclosureSectionHeader() {
  const { toggleStatus: _tS, registerId, isOpenedId } = useDisclosureSection()
  const idRef = useRef<string>(uuid())

  useEffect(() => {
    registerId(idRef.current)
  }, [])

  function toggleStatus() {
    _tS(idRef.current)
  }

  const isOpen = isOpenedId(idRef.current)

  return {
    toggleStatus,
    isOpen,
  }
}
