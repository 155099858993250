import { useIntegration } from '@integration-app/react'
import {
  CreateFieldMappingRequest,
  FieldMapping,
  FieldMappingDirection,
} from '@integration-app/sdk'
import { SimpleInput } from '@integration-app/ui'
import Button, { IconButton } from '@integration-app/ui/Button'
import { Cell, Row } from '@integration-app/ui/DataBuilderUI'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { ContentRows } from 'components/ContentRows'
import DataSourceSelect from 'components/DataSources/DataSourceSelect'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import useIntegrationElement from 'components/IntegrationElements/hooks/useIntegrationElement'
import { useSyncKeyWithName } from 'components/IntegrationElements/hooks/useSyncKeyWithName'
import SimpleSelect from 'components/SimpleSelect'
import { useState } from 'react'
import { Popup } from 'routes/components/Popup'
import clsx from 'utils/clsx'
import { FIELD_MAPPING_DIRECTION_OPTIONS } from '../../FieldMapping/components/FieldMappingConfiguration'

type StepCreateFieldMappingProps = {
  title?: string
  onCreate(item: FieldMapping): void
  onBack?(): void
  integrationId?: string
  readOnlyIntegration?: boolean
  dataSourceKey?: string
  readOnlyDataSource?: boolean
  direction?: FieldMappingDirection
}

export function StepCreateFieldMapping({
  title,
  onBack,
  onCreate,
  integrationId,
  readOnlyIntegration,
  dataSourceKey,
  readOnlyDataSource,
  direction,
}: StepCreateFieldMappingProps) {
  const { createItem } = useIntegrationElement<FieldMapping>({
    route: 'field-mappings',
  })
  const [item, setItem] = useState<Partial<CreateFieldMappingRequest>>({
    integrationId,
    dataSourceKey,
    direction,
  })

  function handleChange(data: Partial<CreateFieldMappingRequest>) {
    setItem((prevItem) => ({ ...prevItem, ...data }))
  }

  const handleNameChange = useSyncKeyWithName(handleChange)

  async function handleCreate() {
    const createdFieldMapping = await createItem({ ...item })
    onCreate(createdFieldMapping)
  }

  const isCreateDataValid = !!item.key && !!item.name

  const directionOptions = FIELD_MAPPING_DIRECTION_OPTIONS.filter(
    (option) => option.value !== FieldMappingDirection.IMPORT,
  )

  return (
    <form onSubmit={useEventStopPropagation(handleCreate)}>
      <Popup.Header>
        <h2 className={clsx('flex flex-row gap-1', onBack && '-ml-2')}>
          {onBack && (
            <IconButton
              tooltip={'Back to Field mapping type selection'}
              onClick={onBack}
            >
              <SvgIcon type={SvgIconType.ChevronLeft} />
            </IconButton>
          )}
          {title || <CreateFlowTitle integrationId={item.integrationId} />}
        </h2>
      </Popup.Header>

      <ContentRows>
        <SimpleInput
          label={'Name'}
          value={item.name}
          onChange={handleNameChange}
          autoFocus
        />

        <SimpleInput
          label={'Key'}
          value={item.key}
          onChange={(key) => handleChange({ key })}
        />

        {integrationId && (
          <Row>
            <Cell.Name>Integration</Cell.Name>
            <Cell.Input grow>
              <IntegrationComboboxSelect
                variant={'ghost'}
                width={'full'}
                marginBlock={-1}
                useId
                value={item.integrationId}
                onChange={(integrationId?: string) =>
                  handleChange({ integrationId })
                }
                disabled={readOnlyIntegration}
              />
            </Cell.Input>
          </Row>
        )}

        {dataSourceKey && (
          <DataSourceSelect
            integrationId={integrationId}
            dataSourceKey={dataSourceKey}
            onChange={(dataSourceKey) => handleChange({ dataSourceKey })}
            disabled={readOnlyDataSource}
          />
        )}

        {direction && (
          <SimpleSelect
            name='Direction'
            value={direction}
            options={directionOptions}
            onChange={(direction) =>
              handleChange({ direction: direction as FieldMappingDirection })
            }
          />
        )}
      </ContentRows>
      <Popup.Footer>
        <Button
          disabled={!isCreateDataValid}
          onClick={handleCreate}
          type={'submit'}
        >
          Create Field Mapping
        </Button>
      </Popup.Footer>
    </form>
  )
}

function CreateFlowTitle({ integrationId }: { integrationId?: string }) {
  const { integration } = useIntegration(integrationId)
  if (!integrationId) {
    return <>Create Universal Field Mapping</>
  }

  if (!integration?.name) {
    return <>Create Field Mapping</>
  }

  return <>Create Field Mapping for {integration?.name}</>
}
