import { VStack } from 'styled-system/jsx'
import { Code } from 'ui-kit/code'
import * as StyledSwitch from 'ui-kit/styled/switch'
import { Text } from 'ui-kit/text'
import { useGenericConfig } from '../contexts/generic-config-context'

export function IncludeRawFieldsBlock(props: StyledSwitch.RootProps) {
  const { patchFieldMappingConfig, fieldMappingConfig } = useGenericConfig()

  return (
    <StyledSwitch.Root
      checked={fieldMappingConfig.includeRawFields}
      onCheckedChange={({ checked }) =>
        patchFieldMappingConfig({ includeRawFields: checked })
      }
      alignItems={'start'}
      marginBlockStart={2}
      {...props}
    >
      <StyledSwitch.Control mt={1}>
        <StyledSwitch.Thumb />
      </StyledSwitch.Control>
      <VStack gap={0} alignItems={'stretch'}>
        <StyledSwitch.Label>Include Raw Fields</StyledSwitch.Label>
        <Text color={'fg.muted'} m={0}>
          If enabled - adds <Code>rawFields</Code> property to the returned
          records. It contains the original fields returned by the external
          application.
        </Text>
      </VStack>
      <StyledSwitch.HiddenInput />
    </StyledSwitch.Root>
  )
}
