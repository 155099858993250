import { PropsWithChildren } from 'react'
import clsx from 'utils/clsx'
import Checkbox from '@integration-app/ui/Checkbox'
import { MarkdownViewer } from 'routes/components/MarkdownViewer'
import classes from './ToDoSection.module.css'
import DocLink from 'components/Docs/DocLink'

function ToDoItemContainter({
  className,
  completed,
  onClick,
  children,
}: PropsWithChildren<{
  className?: string
  completed?: boolean
  onClick?: () => void
}>) {
  return (
    <div className={clsx(classes.todoItem, className)}>
      <div className={classes.todoItem_checkbox} onClick={onClick}>
        <Checkbox onChange={onClick} checked={completed} />
      </div>
      <div
        className={clsx(
          classes.todoItem_content,
          completed && classes.todoItem_content__completed,
        )}
      >
        {children}
      </div>
    </div>
  )
}

function ToDoItemHeader({ children }) {
  return <h4>{children}</h4>
}

function ToDoItemDescription({ children }) {
  return <p>{children}</p>
}

function ToDoItemMarkdownDescription({ children }) {
  if (!children) return null
  return <ToDoMarkdownViewer>{children}</ToDoMarkdownViewer>
}

export const ToDoItem = Object.assign(ToDoItemContainter, {
  Header: ToDoItemHeader,
  Description: ToDoItemDescription,
  MarkdownDescription: ToDoItemMarkdownDescription,
})

const markdownOptions = {
  overrides: {
    a: ({ children, href }) => {
      if (href.startsWith('https://console.integration.app/docs')) {
        return (
          <DocLink
            showTag
            path={href.replace('https://console.integration.app/docs/', '')}
          >
            {children}
          </DocLink>
        )
      }
      return <a href={href}>{children}</a>
    },
  },
}

function ToDoMarkdownViewer({ children }) {
  return (
    <p>
      <MarkdownViewer options={markdownOptions}>{children}</MarkdownViewer>
    </p>
  )
}
