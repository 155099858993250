import { ErrorData } from '@integration-app/sdk'
import { ReactNode, useState } from 'react'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { JsonViewer } from 'components/JsonViewer'
import classes from './BottomErrors.module.css'
import { getArticle } from 'routes/Docs/helpers/articleList'
import clsx from 'utils/clsx'

export function BottomErrors({
  title,
  errors,
  reverse = false,
  isInSidebar = true,
  collapsedByDefault = true,
}: {
  title: string | ((n: number) => string)
  errors?: ErrorData[]
  reverse?: boolean
  isInSidebar?: boolean
  collapsedByDefault?: boolean
}) {
  if (!errors || errors.length === 0) return null

  const [collapsed, setCollapsed] = useState(collapsedByDefault)
  const [selectedError, setSelectedError] = useState<ErrorData | null>(null)

  function handleClick() {
    setCollapsed(!collapsed)
    if (!collapsed) setSelectedError(null)
  }

  const computedTitle = typeof title === 'string' ? title : title(errors.length)

  const chevronExpandedIconType = reverse
    ? SvgIconType.ChevronUp
    : SvgIconType.ChevronDown

  function Bar() {
    return (
      <div className={classes.panelBar} onClick={handleClick}>
        <span>{computedTitle}</span>

        <SvgIcon
          type={collapsed ? SvgIconType.ChevronRight : chevronExpandedIconType}
          className='m-1 w-4 font-bold'
        />
      </div>
    )
  }

  const sidePanelClass = isInSidebar
    ? reverse
      ? classes.sidePanelTop
      : classes.sidePanelBottom
    : ''

  return (
    <div className={clsx(classes.panel, sidePanelClass)}>
      {!reverse && <Bar />}

      {!collapsed && (
        <div className={classes.errorsListWrapper}>
          {!selectedError &&
            errors.map((error, i) => (
              <ErrorItem
                error={error}
                key={i}
                onDetails={() => setSelectedError(error)}
                isLast={i === errors.length - 1}
              />
            ))}

          {selectedError && (
            <ErrorItemDetailed
              error={selectedError}
              onBackToList={() => setSelectedError(null)}
            />
          )}
        </div>
      )}

      {reverse && <Bar />}
    </div>
  )
}

function ErrorItemDetailed({
  error,
  onBackToList,
}: {
  error: ErrorData
  onBackToList()
}) {
  if (!error) {
    return null
  }
  const { data, logs, causedByError } = error

  const additionalData: any = {}
  if (data) {
    additionalData.data = data
  }
  if (logs) {
    additionalData.logs = logs
  }
  if (causedByError) {
    additionalData.causedByError = causedByError
  }

  return (
    <ErrorItemBody error={error}>
      <div
        className='flex items-center p-4 text-primaryColor cursor-pointer hover:opacity-70'
        onClick={onBackToList}
      >
        <SvgIcon type={SvgIconType.ChevronLeft} className='w-4 h-4' />{' '}
        <span>Back to list of errors</span>
      </div>

      <div className={classes.detailedDataBlock}>
        <b>Details</b>

        <div className={classes.detailedJsonData}>
          <JsonViewer json={additionalData} />
        </div>
      </div>
    </ErrorItemBody>
  )
}

function ErrorItem({
  error,
  onDetails,
  isLast,
}: {
  error: ErrorData
  onDetails()
  isLast: boolean
}) {
  if (!error) {
    return null
  }
  const { data, logs, causedByError } = error

  if (!data && !causedByError && !logs)
    return <ErrorItemBody error={error} bottomBorder={!isLast} />

  return (
    <ErrorItemBody error={error} bottomBorder={!isLast}>
      <div onClick={onDetails} className={classes.truncatedDataBlock}>
        <code>{getErrorDataShortened(data, causedByError, logs)}</code>

        <div className='ml-2 border border-black rounded-full'>
          <SvgIcon type={SvgIconType.ArrowRight} className='w-5 h-5' />
        </div>
      </div>
    </ErrorItemBody>
  )
}

function ErrorItemBody({
  error,
  children,
  bottomBorder = false,
}: {
  error: ErrorData
  bottomBorder?: boolean
  children?: ReactNode
}) {
  const { type, message, doc, key } = error

  const docLink = 'https://console.integration.app/docs/errors/' + doc
  const docTitle = `How to resolve this issue: ${
    getArticle('engine/activity-log/errors/' + doc)?.title
  }`

  return (
    <div
      className={clsx(
        classes.errorItemBody,
        bottomBorder && classes.errorItemBodyBorder,
      )}
    >
      <div className='px-6'>
        <span>
          <b>{type}</b> {key && <span>{key}</span>}
        </span>

        <p>{message}</p>

        {doc && (
          <div className='py-3'>
            <DocsLabelLink link={docLink} title={docTitle} />
          </div>
        )}
      </div>

      {children && children}
    </div>
  )
}

function DocsLabelLink({ link, title }: { link: string; title: string }) {
  return (
    <a href={link} target='_blank'>
      <span className={classes.docsLabel}>docs</span>
      <span className='text-primaryColor ml-2 hover:opacity-80'>
        {title ? title : 'Link to related documentation article.'}
      </span>
    </a>
  )
}

function getErrorDataShortened(data: any, causedByError: any, logs: any) {
  if (!data && !causedByError && !logs) return ''

  const json = JSON.stringify({ data, causedByError, logs }, null, ' ').slice(
    0,
    65,
  )
  return json.length === 65 ? json + '... }' : json
}
