import { getDocRelativeLink } from 'components/Docs/DocLinksGrid/helpers'
import { PropsWithChildren, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import { getArticle } from 'routes/Docs/helpers/articleList'
import { cx, css, Styles } from 'styled-system/css'
import { Icon } from 'ui-kit/icon'
import { DocTagBadge } from './DocTag'

const styles: Styles = {
  display: 'inline-flex',
  alignItems: 'center',
  gap: 1,
}

export default function DocLink({
  path,
  showTag,
  icon,
  children,
  className,
}: PropsWithChildren<{
  path: string
  showTag?: boolean
  icon?: ReactElement
  className?: string
}>) {
  const to = getDocRelativeLink(path)

  return (
    <NavLink to={to} className={cx(css(styles), className)}>
      {children || getArticle(path)?.title}
      {!!icon && (
        <Icon size={'sm'} mt={0.5}>
          {icon}
        </Icon>
      )}
      {showTag && <DocTagBadge />}
    </NavLink>
  )
}
