import clsx from 'utils/clsx'
import { HTMLAttributes, ReactNode } from 'react'
import CodeHighlighter, { ICodeHighlighter } from '../../CodeHighlighter'
import { JsonViewer } from '../../JsonViewer'

function CodeBlockElementRoot({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={clsx(
        'border border-neutral02 rounded overflow-hidden',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

type CodeBlockElementSectionProps = HTMLAttributes<HTMLDivElement> & {
  title?: ReactNode
  rightSlot?: ReactNode
}

function CodeBlockElementSection({
  children,
  className,
  title,
  rightSlot,
  ...props
}: CodeBlockElementSectionProps) {
  return (
    <div
      className={clsx(
        '[&:not(:first-child)]:border-t border-neutral02 p-2.5 pl-4',
        !!rightSlot && 'flex flex-row justify-between gap-8',
        className,
      )}
      {...props}
    >
      <div className={'flex-grow'}>
        {title && (
          <h3 className={'!mt-2 !mb-3 !text-neutral08 !text-base !font-bold'}>
            {title}
          </h3>
        )}
        {children}
      </div>
      {!!rightSlot && <div>{rightSlot}</div>}
    </div>
  )
}

function CodeBlockElementCode(props: Omit<ICodeHighlighter, 'className'>) {
  return (
    <CodeBlockElementSection className={'py-4 pr-4 pt-1.5'}>
      <CodeHighlighter {...props} />
    </CodeBlockElementSection>
  )
}

function CodeBlockElementOutput({
  output,
  title,
}: {
  output: unknown
  title?: CodeBlockElementSectionProps['title']
}) {
  return (
    <CodeBlockElementSection title={title}>
      {typeof output === 'object' ? (
        <div className={'pl-2'}>
          <JsonViewer json={output} />
        </div>
      ) : (
        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error TS(2339): Property 'toString' does not exist on type 'never'... Remove this comment to see the full error message
        output.toString()
      )}
    </CodeBlockElementSection>
  )
}

const CodeBlockElement = {
  Root: CodeBlockElementRoot,
  Section: CodeBlockElementSection,
  Code: CodeBlockElementCode,
  Output: CodeBlockElementOutput,
}

export { CodeBlockElement }
