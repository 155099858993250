import ExampleUIBlock from '../../Docs/ExampleUIBlock'
import { CodeParamType } from '../../Docs/codeBlock-types'
import { useIntegration, useIntegrationApp } from '@integration-app/react'
import { useState } from 'react'
import { SimpleForm, SimpleLayout } from '../../Docs/SimpleLayout'

export function ConnectionWidgetUiCodeExample() {
  return (
    <ExampleUIBlock
      customCodes={{ jsx }}
      Component={Component}
      parameters={{
        INTEGRATION_KEY: CodeParamType.integrationKeyWithoutConnection,
        PARAMETERS: CodeParamType.Object,
      }}
    />
  )
}

const jsx = `import { useState } from 'react'
import { userIntegration, useIntegrationApp } from '@integration-app/react'

function Component({ parameters }) {
  // For this to work, don't forget to wrap this component into <IntegrationAppProvider/>
  const integrationApp = useIntegrationApp()

  const { integration, error } = useIntegration(parameters.INTEGRATION_KEY)

  const [connectionParameters, setConnectionParameters] = useState({})
  const [connecting, setConnecting] = useState(false)
  const [connection, setConnection] = useState(null)

  // If something bad happened - show the error
  if (error) {
    return <p>Error: {error.message}</p>
  }

  // Wait until the spec loads
  if (!integration) {
    return <p>Loading...</p>
  }

  // Display only the first auth option
  const authOption = integration!.authOptions?.[0]

  if (!authOption) {
    return <p>No auth options found for this integration</p>
  }

  // Get connection parameters schema
  const schema = authOption.ui?.schema

  // Simplified way to get the list of connection parameters.
  const fields = schema ? Object.keys(schema.properties ?? {}) : []

  async function connect() {
    setConnecting(true)
    try {
      const connection = await integrationApp
        .integration(parameters.INTEGRATION_KEY)
        .connect({
          parameters: connectionParameters,
          authOptionKey: authOption.key,
        })
      setConnection(connection)
    } finally {
      setConnecting(false)
    }
  }

  return (
    <div>
      {fields.length > 0 && (
        <div>
          <strong>Connection Parameters:</strong>
          {fields.map((field) => (
            <div key={field}>
              <label>{field}</label>
              <input
                type='text'
                value={connectionParameters[field] || ''}
                onChange={(e) =>
                  setConnectionParameters({
                    ...connectionParameters,
                    [field]: e.target.value,
                  })
                }
              />
            </div>
          ))}
        </div>
      )}

      {connecting ? (
        <span>Connecting...</span>
      ) : (
        <button onClick={connect}>Connect</button>
      )}

      {connection && (
        <div>
          <strong>Connected!</strong>
          <br />
          Connection Id: {connection.id}
        </div>
      )}
    </div>
}`

function Component({ parameters }) {
  // For this to work, don't forget to wrap this component into <IntegrationAppProvider/>
  const integrationApp = useIntegrationApp()

  const { integration, error } = useIntegration(parameters.INTEGRATION_KEY)

  const [connectionParameters, setConnectionParameters] = useState({})
  const [connecting, setConnecting] = useState(false)
  const [connection, setConnection] = useState(null)

  // If something bad happened - show the error
  if (error) {
    return <p>Error: {error.message}</p>
  }

  // Wait until the spec loads
  if (!integration) {
    return <p>Loading...</p>
  }

  // Display only the first auth option
  const authOption = integration!.authOptions?.[0]

  if (!authOption) {
    return <p>No auth options found for this integration</p>
  }

  const schema = authOption.ui?.schema

  // Simplified way to get the list of connection parameters.
  const fields = schema ? Object.keys(schema.properties ?? {}) : []

  async function connect() {
    setConnecting(true)
    try {
      const connection = await integrationApp
        .integration(parameters.INTEGRATION_KEY)
        .connect({
          parameters: connectionParameters,
          authOptionKey: authOption?.key,
        })
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2345): Argument of type 'Connection' is not assignable to... Remove this comment to see the full error message
      setConnection(connection)
    } finally {
      setConnecting(false)
    }
  }

  return (
    <SimpleLayout>
      {fields.length > 0 && (
        <SimpleForm>
          <strong>Connection Parameters:</strong>
          {fields.map((field) => (
            <div key={field}>
              <label>{field}</label>
              <input
                type='text'
                value={connectionParameters[field] || ''}
                onChange={(e) =>
                  setConnectionParameters({
                    ...connectionParameters,
                    [field]: e.target.value,
                  })
                }
              />
            </div>
          ))}
        </SimpleForm>
      )}

      {connecting ? (
        <span>Connecting...</span>
      ) : (
        <button onClick={connect}>Connect</button>
      )}

      {connection && (
        <div>
          <strong>Connected!</strong>
          <br />
          {/* FIXME: strictNullCheck temporary fix */}
          {/* @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'. */}
          Connection Id: {connection.id}
        </div>
      )}
    </SimpleLayout>
  )
}
