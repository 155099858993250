import { ReactNode, useRef } from 'react'
import { sva } from 'styled-system/css'
import { Box, BoxProps } from 'styled-system/jsx'
import { Heading, HeadingProps } from 'ui-kit/heading'
import { useResizeObserver } from 'usehooks-ts'

const cssVarLastElementOffsetTop = '--branch-last-element-offset-top'
const cssVarBranchArcOffsetTop = '--branch-arc-offset-top'

const iconInSlotRecipe = {
  '& > svg': {
    display: 'block',
  },
  '& > img': {
    display: 'block',
  },
}

const cardRecipe = sva({
  className: 'configCard',
  slots: ['wrapper', 'nestedWrapper', 'root', 'header', 'title', 'content'],
  base: {
    wrapper: {
      backgroundColor: 'gray.1',
      backgroundImage:
        'radial-gradient(token(colors.gray.a4) 1px, transparent 1px)',
      backgroundPosition: '0.05rem 0.15rem',
      backgroundSize: '0.75rem 0.75rem',
      // CSS Hack prevents the nested items from growing beyond its container
      // It works when parent has specified width or display: grid
      display: 'grid',
      alignContent: 'flex-start',
      padding: 4,
      // Every root has `marginBlockStart: 4`.
      // We use `paddingBlockStart: 0,` to remove extra padding from the first element
      paddingBlockStart: 0,
    },
    nestedWrapper: {
      [cssVarLastElementOffsetTop]: 0,
      [cssVarBranchArcOffsetTop]: '4px',
      position: 'relative',
      // CSS Hack prevents the nested items from growing beyond its container
      // It works when parent has specified width or display: grid
      display: 'grid',
      alignContent: 'flex-start',
      maxWidth: '3xl',
      paddingInlineStart: 10,
      // Branch Line
      _before: {
        position: 'absolute',
        content: '""',
        top: 0,
        left: 5,
        width: '1px',
        height: `calc(var(${cssVarLastElementOffsetTop}) + var(${cssVarBranchArcOffsetTop}) + 1px)`,
        backgroundColor: 'border.subtle',
      },
    },
    root: {
      position: 'relative',
      borderWidth: 1,
      borderColor: 'border.subtle',
      borderRadius: 'newui.cardRadius',
      backgroundColor: 'bg.default',
      marginBlockStart: 4,
      // CSS Hack prevents the flex item from growing beyond its container
      minWidth: '0',
      maxWidth: '3xl',
      // Branch Arc
      _before: {
        position: 'absolute',
        display: 'block',
        top: `min(calc(50% - token(sizes.4)), var(${cssVarBranchArcOffsetTop}))`,
        right: 'calc(100% + 1px)',
        width: 5,
        height: 5,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderLeftColor: 'border.subtle',
        borderBottomColor: 'border.subtle',
        borderRadius: '0 0 0 75%',
      },
      // Show branch arc only for elements that are nested
      // @ts-expect-error TS check fails, but it's valid selector
      '.configCard__nestedWrapper > &::before': {
        content: '""',
      },
      _after: {
        pointerEvents: 'none',
        position: 'absolute',
        display: 'block',
        top: '-0.5px',
        bottom: '-0.5px',
        left: 0,
        width: 8,
        borderTopLeftRadius: 'newui.cardRadius',
        borderBottomLeftRadius: 'newui.cardRadius',
        boxShadow: '-3px 0 0 0px var(--shadow-color)',
      },
    },
    header: {
      px: 3,
      '&:has(+ *)::after': {
        content: '""',
        display: 'block',
        width: 'full',
        borderBottomWidth: 1,
        borderBottomColor: 'border.subtle',
      },
    },
    title: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      gap: 4,
      fontSize: 'md',
      fontWeight: 'semibold',
      lineHeight: 'normal',
    },
    content: {
      padding: 3,
    },
  },
  variants: {
    variant: {
      primary: {
        root: {
          _after: {
            content: '""',
            boxShadowColor: 'primary.emphasized',
          },
        },
      },
      success: {
        root: {
          _after: {
            content: '""',
            boxShadowColor: 'success.emphasized',
          },
        },
      },
      warning: {
        root: {
          _after: {
            content: '""',
            boxShadowColor: 'warning.emphasized',
          },
        },
      },
      error: {
        root: {
          _after: {
            content: '""',
            boxShadowColor: 'error.emphasized',
          },
        },
      },
      ghost: {
        root: {
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          alignSelf: 'start',
          alignItems: 'center',
          '& button': {
            borderRadius: 'newui.cardRadius',
          },
        },
      },
    },
  },
})

const headerRecipe = sva({
  className: 'configCardHeader',
  slots: ['wrapper', 'leftSlot', 'rightSlot', 'menuSlot'],
  base: {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      minHeight: 12,
      gap: 2,
    },
    leftSlot: iconInSlotRecipe,
    rightSlot: {
      display: 'flex',
      gap: 2,
      alignItems: 'center',
      justifyContent: 'start',
      ...iconInSlotRecipe,
    },
    menuSlot: {
      marginInlineStart: 'auto',
      display: 'flex',
      gap: 2,
      alignItems: 'center',
      justifyContent: 'end',
      ...iconInSlotRecipe,
    },
  },
})

function Wrapper({ children, ...props }: BoxProps) {
  const ref = useRef<HTMLDivElement>(null)
  const classes = cardRecipe()

  const pureClassNames = Object.keys(classes).reduce((acc, key) => {
    acc[key] = classes[key].split(' ').pop()
    return acc
  }, {}) as Record<keyof typeof classes, string>

  useResizeObserver({
    ref,
    box: 'border-box',
    onResize: calcBranchLastElementOffsetTop,
  })

  function calcBranchLastElementOffsetTop() {
    const nestedWrappers = (ref?.current?.getElementsByClassName(
      pureClassNames.nestedWrapper,
    ) ?? []) as HTMLCollectionOf<HTMLDivElement>

    for (const nestedWrapper of nestedWrappers) {
      const lastElement = Array.from(
        nestedWrapper.querySelectorAll(`:scope > .${pureClassNames.root}`),
      ).pop() as HTMLElement | undefined

      const lastElementOffsetTop = lastElement?.offsetTop ?? 0

      nestedWrapper.style.setProperty(
        cssVarLastElementOffsetTop,
        `${lastElementOffsetTop}px`,
      )
    }
  }

  return (
    <Box ref={ref} className={classes.wrapper} {...props}>
      {children}
    </Box>
  )
}

function NestedWrapper({ children, ...props }: BoxProps) {
  const classes = cardRecipe()
  return (
    <Box className={classes.nestedWrapper} {...props}>
      {children}
    </Box>
  )
}

function Root({
  children,
  variant,
  ...props
}: {
  variant?: 'ghost' | 'success' | 'warning' | 'error' | 'primary'
} & BoxProps) {
  const classes = cardRecipe({ variant })
  return (
    <Box className={classes.root} {...props}>
      {children}
    </Box>
  )
}

function Header({
  children,
  leftSlot,
  rightSlot,
  menuSlot,
  ...props
}: BoxProps & {
  leftSlot?: ReactNode
  rightSlot?: ReactNode
  menuSlot?: ReactNode
}) {
  const classes = cardRecipe()
  const headerClasses = headerRecipe()

  return (
    <Box className={classes.header} {...props}>
      <Box className={headerClasses.wrapper}>
        <HeaderSlot className={headerClasses.leftSlot}>{leftSlot}</HeaderSlot>
        {children}
        <HeaderSlot className={headerClasses.rightSlot}>{rightSlot}</HeaderSlot>
        <HeaderSlot className={headerClasses.menuSlot}>{menuSlot}</HeaderSlot>
      </Box>
    </Box>
  )
}

function HeaderSlot({ children, ...props }: BoxProps) {
  if (!children) return null
  return <Box {...props}>{children}</Box>
}

function Title({ children, ...props }: HeadingProps) {
  const classes = cardRecipe()
  return (
    <Heading as={'h3'} className={classes.title} {...props}>
      {children}
    </Heading>
  )
}

function Content({ children, ...props }: BoxProps) {
  const classes = cardRecipe()
  return (
    <Box className={classes.content} {...props}>
      {children}
    </Box>
  )
}

const ConfigCard = {
  Wrapper,
  NestedWrapper,
  Root,
  Header,
  Title,
  Content,
}

export { ConfigCard }
