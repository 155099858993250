import { Tooltip } from 'components/Tooltip'
import { ReactNode } from 'react'
import { Icon } from 'ui-kit/icon'
import {
  IconButtonProps,
  IconButton as UiKitIconButton,
} from 'ui-kit/icon-button'

// Simple implementation of `IconButton`
// that forces to use tooltip and provides ARIA compatibility
export function IconButton({
  children,
  tooltip,
  ...props
}: IconButtonProps & {
  tooltip: ReactNode
}) {
  return (
    <Tooltip tooltip={tooltip}>
      <UiKitIconButton {...props}>
        <Icon>{children}</Icon>
      </UiKitIconButton>
    </Tooltip>
  )
}
