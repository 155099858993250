import { SvgIconType } from '@integration-app/ui/SvgIcon'
import clsx from 'utils/clsx'
import { CommonRoundedButton } from '../NodeBody/CommonRoundedButton'
import classes from './Edge.module.css'

import { useIsInRoute } from 'components/SPA'
import QueryString from 'query-string'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFlowBuilder } from 'routes/Workspaces/Workspace/components/FlowBuilder/flow-builder-context'

export function AddNodePlusButton({
  parent,
  child,
  linkIndex,
}: {
  parent: string
  child: string
  linkIndex: number
}) {
  const { isAdding, start } = useAddingNode({ parent, child, linkIndex })

  return (
    <CommonRoundedButton
      squared
      onClick={start}
      iconType={SvgIconType.Add}
      className={clsx(classes.plusButton, isAdding && classes.plusButtonActive)}
    />
  )
}

export function useAddingNode({
  parent,
  child,
  linkIndex = 0,
  subgraphParent,
}: {
  parent: string
  child: string
  linkIndex?: number
  subgraphParent?: string
}) {
  const navigate = useNavigate()
  const { baseRoute } = useFlowBuilder()

  const [searchParams] = useSearchParams()

  const query = Object.fromEntries(searchParams.entries()) as {
    subgraphParent: string
    parent: string
    child: string
    linkIndex: string
    linking: string
  }

  const isInRoute = useIsInRoute()

  const isAdding =
    isInRoute('add-node') &&
    query.child === child &&
    query.parent === parent &&
    query.linkIndex === linkIndex.toString()

  const isLinking = query.linking

  function start() {
    navigate({
      pathname: `${baseRoute}/add-node`,
      search: QueryString.stringify({
        parent,
        child,
        linkIndex,
        subgraphParent,
      }),
    })
  }

  return {
    isAdding,
    isLinking,
    start,
  }
}
