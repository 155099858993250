import { IHasKeyAndName } from './helpers'
import { getSlugKey } from 'utils/slug'

/*
 * Hook returning handler for `name` change that will also update `key` (as a slug of `name`)
 */
export function useSyncKeyWithName(
  onChange: (data: Partial<IHasKeyAndName>) => void,
  syncKey = true,
  keyToSync = 'key',
) {
  return function (name: string) {
    const data: Partial<IHasKeyAndName> = { name }

    if (syncKey) {
      data[keyToSync] = getSlugKey(name)
    }

    onChange(data)
  }
}
