import { DataSchema } from '@integration-app/sdk'
import useWorkspace from 'components/Workspaces/workspace-context'
import { useState } from 'react'
import { TbWand } from 'react-icons/tb'
import { Button } from 'ui-kit/button'
import { Icon } from 'ui-kit/icon'

export function GenerateFieldMappingButton({
  fromSchema,
  toSchema,
  extraPrompt,
  onGenerate,
}: {
  fromSchema: DataSchema
  toSchema: DataSchema
  extraPrompt?: string
  onGenerate: (value: any) => void
}) {
  const { engineAdminClient } = useWorkspace()
  const [generating, setGenerating] = useState(false)

  async function handleGenerate() {
    setGenerating(true)
    try {
      const result = await engineAdminClient.post('/copilot/map-data-schemas', {
        fromSchema,
        toSchema,
        extraPrompt,
      })
      onGenerate(result)
    } finally {
      setGenerating(false)
    }
  }

  return (
    <Button
      variant='outline'
      size='dense'
      colorPalette='primary'
      onClick={handleGenerate}
      disabled={generating}
      loading={generating}
      loadingText={generating ? 'Generating...' : undefined}
      alignSelf={'start'}
    >
      <Icon>
        <TbWand />
      </Icon>
      Generate
    </Button>
  )
}
