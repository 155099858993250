import {
  DataSchema,
  FieldMappingDirection,
  buildDataSchema,
  getLocatorsFromSchema,
  getSchemaByLocator,
  locatorToField,
  schemaWithTitle,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { Alert } from 'components/Alert'
import { TbInfoCircle } from 'react-icons/tb'
import { VStack } from 'styled-system/jsx'
import type { ComponentProps } from 'styled-system/types'
import useSWR from 'swr'
import { Text } from 'ui-kit/text'
import useWorkspace from '../../../../../../components/Workspaces/workspace-context'
import { useGenericConfig } from '../contexts/generic-config-context'
import { FieldMappingSelect } from '../FieldMappingSelect'

export function SharedExportFieldMapping() {
  const { engineAdminFetcher } = useWorkspace()
  const {
    patchFieldMappingConfig,
    fieldMappingConfig,
    fieldMapping,
    integrationId,
    variablesSchema,
    editableVariablesSchemaLocators,
    handleAddVariable,
  } = useGenericConfig()

  const { data: appSchema } = useSWR(
    fieldMapping?.id
      ? `/field-mappings/${fieldMapping.id}/app-schema`
      : undefined,
    engineAdminFetcher,
  )

  return (
    <VStack alignItems={'stretch'} gap={4}>
      <VStack alignItems={'stretch'} gap={2}>
        <Text m={0}>
          This field mapping will be synchronized between all actions and flows
          that use it.
        </Text>
        <FieldMappingSelect
          fieldMappingKey={fieldMappingConfig.key}
          integrationId={integrationId}
          direction={FieldMappingDirection.EXPORT}
          onChange={(key) => patchFieldMappingConfig({ key })}
        />
      </VStack>

      {fieldMapping && (
        <VStack alignItems={'stretch'} gap={2}>
          <Text m={0}>
            This field mapping requires input in a specific format. Please
            provide it below.
          </Text>

          <DataBuilderForm
            field={makeDataField({
              value: fieldMappingConfig.input,
              schema: schemaWithTitle(appSchema, 'Field Mapping Input'),
              variablesSchema,
            })}
            onChange={(input: any) => patchFieldMappingConfig({ input })}
            editableVariablesSchemaLocators={editableVariablesSchemaLocators}
            onAddVariable={handleAddVariable}
            hideReadOnlyFields
          />

          <SchemaMismatchWarning
            expectedSchema={fieldMapping?.appSchema}
            actualSchema={buildDataSchema(
              fieldMappingConfig.input,
              variablesSchema,
            )}
            marginBlockStart={4}
          />
        </VStack>
      )}
    </VStack>
  )
}

function SchemaMismatchWarning({
  expectedSchema,
  actualSchema,
  ...props
}: ComponentProps<typeof Alert.Root> & {
  expectedSchema: DataSchema | undefined
  actualSchema: DataSchema
}) {
  const expectedSchemaFields = getLocatorsFromSchema(expectedSchema)
  const actualSchemaFields = getLocatorsFromSchema(actualSchema)

  const mismatchedFields: Record<string, { expected: string; actual: string }> =
    {}
  actualSchemaFields.forEach((field) => {
    const expectedFieldSchema = getSchemaByLocator(expectedSchema, field)
    const actualFieldSchema = getSchemaByLocator(actualSchema, field)
    if (
      expectedFieldSchema?.type &&
      actualFieldSchema?.type &&
      actualFieldSchema?.type !== expectedFieldSchema.type
    ) {
      mismatchedFields[field] = {
        expected: expectedFieldSchema.type,
        actual: actualFieldSchema?.type,
      }
    }
  })

  const unexpectedFieldsPresent = actualSchemaFields
    .filter((field) => !expectedSchemaFields.includes(field))
    .map(locatorToField)

  if (expectedSchemaFields.length) {
    if (!actualSchemaFields.length) {
      return (
        <Alert.Root variant='warning' icon={<TbInfoCircle />} {...props}>
          <Alert.Title>
            An input value is required for field mapping to function.
          </Alert.Title>
        </Alert.Root>
      )
    } else {
      if (unexpectedFieldsPresent.length > 0) {
        return (
          <Alert.Root variant='warning' icon={<TbInfoCircle />} {...props}>
            <Alert.Title>
              The input value contains unexpected fields
            </Alert.Title>
            <Alert.Description>
              <Text m={0}>Please check if the input value is correct.</Text>
              <ul className='list-disc'>
                {unexpectedFieldsPresent.slice(0, 5).map((field) => (
                  <li>{field}</li>
                ))}
              </ul>
            </Alert.Description>
          </Alert.Root>
        )
      } else if (Object.keys(mismatchedFields).length > 0) {
        return (
          <Alert.Root variant='warning' icon={<TbInfoCircle />} {...props}>
            <Alert.Title>
              The input value contains fields with mismatched types:
            </Alert.Title>
            <Alert.Description>
              {Object.entries(mismatchedFields)
                .map(([field, { expected, actual }]) => {
                  return `${field} (expected ${expected}, got ${actual})`
                })
                .join(', ')}
            </Alert.Description>
          </Alert.Root>
        )
      }
    }
  }

  return null
}
