import { DataSchema, schemaWithTitle } from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui'
import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { Text } from 'ui-kit/text'

export function CustomImportFieldMapping({
  value,
  onChange,
  internalFieldsSchema,
  externalFieldsSchema,
}: {
  value: any
  onChange: (value) => void
  internalFieldsSchema: DataSchema
  externalFieldsSchema: DataSchema | undefined
}) {
  return externalFieldsSchema ? (
    <AdminDataBuilderForm
      onChange={onChange}
      field={makeDataField({
        schema: schemaWithTitle(internalFieldsSchema, 'Fields'),
        variablesSchema: externalFieldsSchema,
        value,
      })}
      collapsed={true}
      fieldsToShow={5}
    />
  ) : (
    <Text m={0} fontStyle={'italic'}>
      Custom field mapping configuration is unavailable because data source does
      not provide fields schema. Please configure the data source first.
    </Text>
  )
}
