import {
  getDataCollectionCreateFields,
  getDataCollectionUpdateFields,
  schemaWithTitle,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { ConfigCard } from 'components/ConfigCard'
import { VStack } from 'styled-system/jsx'
import { Text } from 'ui-kit/text'
import { GenerateFieldMappingButton } from '../../../Blueprints/FieldMappings/FieldMapping/components/GenerateFieldMappingButton'
import { useGenericConfig } from '../contexts/generic-config-context'

const DEFAULT_SCHEMA = {
  type: 'object',
  additionalProperties: true,
}

export function ConnectionLevelExportFieldMappingUnitConfig({
  isUpdate = false,
}: {
  isUpdate?: boolean
}) {
  const {
    fieldMappingConfig,
    patchFieldMappingConfig,
    dataCollectionSpec,
    variablesSchema,
    editableVariablesSchemaLocators,
    handleAddVariable,
  } = useGenericConfig()

  async function handleMappingChange(value: any) {
    return patchFieldMappingConfig({
      exportValue: value,
    })
  }

  const isSharedFieldMapping = !!fieldMappingConfig?.key

  const dataCollectionFieldsSchema = isUpdate
    ? getDataCollectionUpdateFields(dataCollectionSpec)
    : getDataCollectionCreateFields(dataCollectionSpec)

  const fieldMappingTargetSchema = schemaWithTitle(
    dataCollectionFieldsSchema ?? DEFAULT_SCHEMA,
    'Fields',
  )

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Fields</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        {isSharedFieldMapping ? (
          <Text m={0}>
            This step uses a shared field mapping. Please configure it there.
          </Text>
        ) : (
          <VStack alignItems={'stretch'} gap={2}>
            {fieldMappingTargetSchema && variablesSchema?.properties?.input && (
              <GenerateFieldMappingButton
                fromSchema={variablesSchema}
                toSchema={fieldMappingTargetSchema}
                onGenerate={handleMappingChange}
              />
            )}

            <DataBuilderForm
              onChange={handleMappingChange}
              field={makeDataField({
                schema: fieldMappingTargetSchema,
                value: fieldMappingConfig.exportValue,
                variablesSchema: variablesSchema,
              })}
              editableVariablesSchemaLocators={editableVariablesSchemaLocators}
              onAddVariable={handleAddVariable}
            />
          </VStack>
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
