import React, { forwardRef, MouseEventHandler, RefObject, useRef } from 'react'
import { SvgIconType } from '@integration-app/ui/SvgIcon'
import { DropdownMenu } from '@integration-app/ui'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import { useRouter } from 'next/router'

import { useCopyToClipboard, CopyToClipboardPopper } from './CopyToClipboard'
import { ITagId, TagId, TagIdCopyToClipboard } from './Tag'

interface ITableCellTagRoot extends ITagId {
  children: string
  copyToClipboard?: boolean
}

const TableCellTagRoot = forwardRef<HTMLSpanElement, ITableCellTagRoot>(
  ({ children, title, copyToClipboard = true, ...props }, ref) => {
    const Component = copyToClipboard ? TagIdCopyToClipboard : TagId
    return (
      <Component title={title || (children as string)} {...props} ref={ref}>
        {children}
      </Component>
    )
  },
)
TableCellTagRoot.displayName = 'TableCellTag'

interface ITagDropdown extends ITagId {
  open?: boolean
}

const TagDropdown = forwardRef<HTMLSpanElement, ITagDropdown>(
  ({ children, open = false, title, onClick, ...props }, ref) => {
    return (
      <TagId
        icon={open ? SvgIconType.ChevronUp : SvgIconType.ChevronDown}
        iconAlways={open}
        onClick={onClick}
        title={title || (children as string)}
        {...props}
        ref={ref}
      >
        {children}
      </TagId>
    )
  },
)
TagDropdown.displayName = 'TagDropdown'

function NameWithDropdown<Item extends { id: string; name?: string }>({
  item,
  truncate = false,
  filterByValue,
  filterByField,
  onDetailsClick,
}: {
  item: Item
  truncate?: boolean
  filterByField?: string
  filterByValue?: string
  onDetailsClick?: MouseEventHandler<HTMLDivElement>
}) {
  const { id, name } = item
  const refElement = useRef<HTMLDivElement>(null)

  return (
    <div ref={refElement}>
      <DropdownOptions
        filterByField={filterByField}
        filterByValue={filterByValue ?? id}
        onDetailsClick={onDetailsClick}
        copyValue={id}
        copyTitle={'Copy'}
        refElement={refElement}
        triggerNode={
          <button
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <TagDropdown truncate={truncate}>{name || id}</TagDropdown>
          </button>
        }
      />
    </div>
  )
}

function DropdownOptions({
  refElement,
  copyValue,
  copyTitle = 'Copy Id',
  filterByValue,
  filterByField,
  onDetailsClick,
  triggerNode,
}: {
  refElement: RefObject<HTMLDivElement>
  copyValue?: string
  copyTitle?: string
  filterByValue?: string
  filterByField?: string
  onDetailsClick?: MouseEventHandler<HTMLDivElement>
  triggerNode: React.ReactNode
}) {
  const router = useRouter()
  const { copied, copyToClipboard } = useCopyToClipboard()

  async function copyOnClick() {
    if (copyValue) {
      await copyToClipboard(copyValue)
    }
  }

  async function filterByOnClick() {
    await router.push({
      query: {
        ...router.query,
        ...(filterByField ? { [filterByField]: filterByValue } : {}),
      },
    })
  }

  return (
    <>
      <CopyToClipboardPopper
        refElement={refElement}
        isOpen={copied}
        offset={[0, -20]}
      />
      <DropdownMenu triggerNode={triggerNode}>
        {copyValue && (
          <DropdownMenu.Option onClick={useEventStopPropagation(copyOnClick)}>
            {copyTitle}
          </DropdownMenu.Option>
        )}

        {onDetailsClick && (
          <DropdownMenu.Option
            onClick={useEventStopPropagation(onDetailsClick)}
          >
            Details
          </DropdownMenu.Option>
        )}

        {filterByField && (
          <DropdownMenu.Option
            onClick={useEventStopPropagation(filterByOnClick)}
          >
            Filter By
          </DropdownMenu.Option>
        )}
      </DropdownMenu>
    </>
  )
}

const TableCellTag = Object.assign(TableCellTagRoot, {
  NameWithDropdown,
})
export default TableCellTag
