import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'utils/clsx'
import {
  getDocRelativeLink,
  useArticleData,
} from 'components/Docs/DocLinksGrid/helpers'
import classes from './DocPanel.module.css'

function DocPanelContainer({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx(classes.container, className)}>{children}</div>
}

function DocPanelItem({ children, src }: PropsWithChildren<{ src: string }>) {
  return (
    <div className={classes.panel}>
      <div className={classes.panel_image}>
        <img src={src} alt='' />
      </div>
      <div className={classes.panel_content}>{children}</div>
    </div>
  )
}

function DocPanelLinks({ children }) {
  return <div className={classes.links}>{children}</div>
}

type IconType = 'drop-in' | 'custom'
function DocPanelLink({
  children,
  to,
  icon,
}: PropsWithChildren<{ to: string; icon?: IconType }>) {
  const { title } = useArticleData(to)
  return (
    <NavLink className={classes.link} to={getDocRelativeLink(to)}>
      {icon && <Icon type={icon} />}
      {children || title}
    </NavLink>
  )
}

function Icon({ type }: { type: IconType }) {
  switch (type) {
    case 'drop-in':
      return <IconDropIn />
    case 'custom':
      return <IconCustom />
    default:
      return null
  }
}

function IconDropIn() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      strokeWidth='1'
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1'></path>
    </svg>
  )
}

function IconCustom() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      strokeWidth='1'
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M3 21v-4a4 4 0 1 1 4 4h-4'></path>
      <path d='M21 3a16 16 0 0 0 -12.8 10.2'></path>
      <path d='M21 3a16 16 0 0 1 -10.2 12.8'></path>
      <path d='M10.6 9a9 9 0 0 1 4.4 4.4'></path>
    </svg>
  )
}

export const DocPanel = Object.assign(DocPanelItem, {
  Container: DocPanelContainer,
  Links: DocPanelLinks,
  Link: DocPanelLink,
})
