import type { Assign } from '@ark-ui/react'
import { RadioGroup as ArkUIRadioGroup } from '@ark-ui/react/radio-group'
import type { HTMLStyledProps } from 'styled-system/types'
import { RadioGroup as UiKitRadioGroup } from 'ui-kit/radio-group'

function Item({
  children,
  ...props
}: Assign<HTMLStyledProps<'label'>, ArkUIRadioGroup.ItemBaseProps>) {
  return (
    <UiKitRadioGroup.Item {...props}>
      <UiKitRadioGroup.ItemControl />
      <UiKitRadioGroup.ItemText>{children}</UiKitRadioGroup.ItemText>
      <UiKitRadioGroup.ItemHiddenInput />
    </UiKitRadioGroup.Item>
  )
}

const RadioGroup = {
  Root: UiKitRadioGroup.Root,
  Item,
}

export { RadioGroup }
