import { FlowInstanceNode } from '@integration-app/sdk'
import { DEFAULT_NODE_HEIGHT } from 'components/FlowBuilder/Graph/elements'
import {
  SourceHandle,
  TargetHandle,
} from 'components/FlowBuilder/Graph/elements/Handle/Handle'
import { LeafEdge } from 'components/FlowBuilder/Graph/elements/LeafEdge/LeafEdge'
import {
  NodeBody,
  NodeBodyType,
} from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBody'
import { NodeBodyContainer } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBodyContainer'
import { calculateNodeStackItems } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeStackItems/calculate'
import { PortalNodesDropdown } from 'components/FlowBuilder/Graph/elements/NodeBody/PortalDropdown'
import {
  getNodeSize,
  getSubFlowNodes,
  isNodeWithSubFlow,
} from 'components/FlowBuilder/Graph/new-layout'
import { linkNodes } from 'components/FlowBuilder/helpers/linking'
import { PropsWithChildren } from 'react'
import {
  Edge,
  getOutgoers,
  Node,
  NodeProps,
  useEdges,
  useNodes,
} from 'reactflow'
import { useFlowBuilder } from 'routes/Workspaces/Workspace/components/FlowBuilder/flow-builder-context'
import { useGenericFlow } from 'routes/Workspaces/Workspace/components/FlowBuilder/useGenericFlow'
import clsx from 'utils/clsx'
import { DeleteNodeButton } from './DeleteNodeButton'

export type RegularNodeData = {
  flowNodeKey: string
  flowNode: FlowInstanceNode
  portalNodes?: Record<string, FlowInstanceNode>
}

export function RegularNode(props: NodeProps<RegularNodeData>) {
  const { flowNode, flowNodeKey } = props.data

  const { isSelected, handleSelectNode } = useNodeSelecting(flowNodeKey)
  const isPortalHovered = useIsHoveringPortalNode(flowNodeKey)
  const isHoveredFromPortalDropdown =
    useIsHoveringFromPortalDropdpwn(flowNodeKey)
  const { isCandidateForLinking, handleLinkNodes } = useNodeLinking(flowNodeKey)

  const isHovered =
    isPortalHovered || isCandidateForLinking || isHoveredFromPortalDropdown

  function handleClick() {
    if (isCandidateForLinking) {
      void handleLinkNodes()
    } else {
      handleSelectNode()
    }
  }

  const nodes = useNodes()
  const edges = useEdges()
  const node = nodes.find((node) => node.id === props.id)

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'Node<unknown, string | undefined... Remove this comment to see the full error message
  const size = getNodeSize(node, nodes, edges)

  return (
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2322): Type 'Node<unknown, string | undefined> | undefine... Remove this comment to see the full error message
    <SubFlowContainer node={node}>
      <NodeStats props={props} />

      <NodeBodyContainer>
        <PortalNodesDropdown {...props} />

        <TargetHandle />

        <RegularOrTriggerNodeBody
          flowNodeKey={flowNodeKey}
          flowNode={flowNode}
          onClick={handleClick}
          isSelected={isSelected}
          isHovered={isHovered}
        />

        <SourceHandle />
      </NodeBodyContainer>

      <LeafEdge nodeHeight={size.height} source={flowNodeKey} />
    </SubFlowContainer>
  )
}

export function SubFlowContainer({
  node,
  children,
}: PropsWithChildren<{ node: Node }>) {
  const nodes = useNodes()
  const edges = useEdges()

  const { flowNodeKey } = node.data as RegularNodeData

  const { selectedNodeKey } = useFlowBuilder()

  const subFlowNodes = getSubFlowNodes(node, nodes, edges)

  const isSelected = [...subFlowNodes.map((n) => n.id), flowNodeKey].includes(
    selectedNodeKey,
  )

  const hideSubFlow = !isNodeWithSubFlow(node) || subFlowNodes.length === 0

  const size = getNodeSize(node, nodes, edges)

  const offset = DEFAULT_NODE_HEIGHT / 2

  return (
    <div
      className={clsx('flex flex-col items-center relative')}
      style={{ width: size.width }}
    >
      {children}

      {!hideSubFlow && (
        <div
          style={{
            width: size.width,
            height: size.height - offset,
            top: offset,
          }}
          className={clsx(
            'rounded border bg-white fixed',
            isSelected ? 'border-neutral06' : 'border-neutral04',
          )}
        />
      )}
    </div>
  )
}

/*
 * debug component to show different info about the node
 */
export function NodeStats({}: { props: NodeProps }) {
  return (
    <div style={{ position: 'absolute', top: -20, left: 0 }}>
      {/*z-index: {props.zIndex}*/}
    </div>
  )
}

/*
 * Can be reused for both regular and trigger nodes since they share similar logic.
 */
export function RegularOrTriggerNodeBody({
  flowNodeKey,
  flowNode,
  isTrigger = false,
  isHovered = false,
  isSelected = false,
  onClick,
}) {
  const { hoverNode, getNodeErrors } = useFlowBuilder()

  const hasError = getNodeErrors(flowNodeKey).length > 0

  const type = isTrigger ? NodeBodyType.Trigger : NodeBodyType.Regular

  const stackItems = calculateNodeStackItems(flowNode)

  return (
    <div className='relative'>
      <NodeBody
        flowNodeKey={flowNodeKey}
        flowNode={flowNode}
        type={type}
        onClick={onClick}
        isSelected={isSelected}
        isHovered={isHovered || isSelected}
        stackItems={stackItems}
        onNodeHover={hoverNode}
        hasError={hasError}
      />
      <DeleteNodeButton nodeKey={flowNodeKey} />
    </div>
  )
}

export function useNodeSelecting(flowNodeKey: string) {
  const { selectedNodeKey, selectNode } = useFlowBuilder()

  function handleSelectNode() {
    selectNode(flowNodeKey)
  }

  const isSelected = flowNodeKey === selectedNodeKey

  return {
    isSelected,
    handleSelectNode,
  }
}

function useNodeLinking(nodeKey: string) {
  const { selectNode, linking } = useFlowBuilder()
  const { flow, patchFlow } = useGenericFlow()

  const isLinking = !!linking

  const parent = linking?.parent ?? ''

  const nodes = useNodes()
  const edges = useEdges()

  const isCandidateForLinking =
    isLinking && parent && !hasChild(nodes, edges, nodeKey, parent)

  async function handleLinkNodes() {
    const updatedFlow = linkNodes(flow, parent, nodeKey)

    patchFlow(updatedFlow).catch(console.error)

    selectNode('')
  }

  return {
    isLinking,
    isCandidateForLinking,
    handleLinkNodes,
  }
}

/*
 * Visit nodes tree downwards starting from `root` and look for `target`
 */
function hasChild(nodes: Node[], edges: Edge[], root: string, target: string) {
  if (root === target) {
    return true
  }

  const rootNode = nodes.find((node) => node.id === root)
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'Node<any, string | undefined> | ... Remove this comment to see the full error message
  const childNodes = getOutgoers(rootNode, nodes, edges)

  return childNodes
    .map((node) => hasChild(nodes, edges, node.id, target))
    .some((c) => c)
}

function useIsHoveringPortalNode(flowNodeKey: string) {
  const { hoveringPortalNodeKey } = useFlowBuilder()

  return hoveringPortalNodeKey === flowNodeKey
}

export function useIsHoveringFromPortalDropdpwn(flowNodeKey: string) {
  const { hoveringNodeKey } = useFlowBuilder()

  return hoveringNodeKey === flowNodeKey
}
