import Button from '@integration-app/ui/Button'
import clsx from 'utils/clsx'
import classes from '../styles.module.css'
import { useAddingNode } from 'components/FlowBuilder/Graph/elements/Edge/AddNodePlusButton'

export function AddNodeButton({
  parent,
  child,
  linkIndex,
  subgraphParent,
}: {
  parent: string
  child: string
  linkIndex: number
  subgraphParent?: string
}) {
  const { isAdding, isLinking, start } = useAddingNode({
    parent,
    child,
    linkIndex,
    subgraphParent,
  })

  const isActive = isAdding && !isLinking

  return (
    <Button
      className={clsx(classes.button, isActive && classes.buttonActive)}
      onClick={start}
      variant='secondary'
    >
      Add Node
    </Button>
  )
}
