import useSWR from 'swr'
import ConnectorEventSelect from '../../../../../../../components/ConnectorEvents/ConnectorEventSelect'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import useWorkspace from '../../../../../../../../../../components/Workspaces/workspace-context'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { makeDataField } from '@integration-app/ui'
import { schemaWithTitle, setSchemaAtLocator } from '@integration-app/sdk'
import DataBuilderFilter from '@integration-app/ui/DataBuilder/Filter'
import {
  ActionBarToggleOnOff,
  CollapsibleSection,
} from 'routes/Workspaces/Workspace/components/Section'

export function ConnectorEventTrigger() {
  const { engineAdminFetcher } = useWorkspace()
  const { config, patchConfig, variablesSchema, integrationId } =
    useGenericConfig()

  const eventKey = config?.eventKey

  const { data: eventSpec } = useSWR(
    integrationId && eventKey
      ? `/integrations/${integrationId}/events/${eventKey}`
      : null,
    engineAdminFetcher,
  )

  const filterEnabled = !!config?.filter

  // Add `input` to the schema available in filter
  let filterSchema = variablesSchema
    ? JSON.parse(JSON.stringify(variablesSchema))
    : undefined
  filterSchema = setSchemaAtLocator(filterSchema, 'input', eventSpec?.schema)

  return (
    <>
      <CollapsibleSection open>
        <CollapsibleSection.Header>Connector Event</CollapsibleSection.Header>

        <ConnectorEventSelect
          value={eventKey}
          onChange={(eventKey) => patchConfig({ eventKey })}
        />
        {eventSpec?.parametersSchema && (
          <DataBuilderForm
            field={makeDataField({
              schema: schemaWithTitle(eventSpec.parametersSchema, 'Parameters'),
              value: config?.eventParameters,
            })}
            onChange={(eventParameters) => patchConfig({ eventParameters })}
          />
        )}
      </CollapsibleSection>

      <CollapsibleSection open>
        <CollapsibleSection.Header>Filter</CollapsibleSection.Header>
        <CollapsibleSection.ActionBar>
          <ActionBarToggleOnOff
            checked={filterEnabled}
            onChange={() =>
              patchConfig({ filter: config?.filter ? undefined : {} })
            }
          />
        </CollapsibleSection.ActionBar>

        {filterEnabled ? (
          <DataBuilderFilter
            value={config?.filter}
            variablesSchema={filterSchema}
            onChange={(filter) => patchConfig({ filter })}
          />
        ) : (
          <p className='italic'>
            Enable to launch the flow only if event matches the filter.
          </p>
        )}
      </CollapsibleSection>
    </>
  )
}
