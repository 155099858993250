import { Node, Edge, getOutgoers, Dimensions } from 'reactflow'
import { GRAPH_NODE_SPECS, GraphNodeType, SUB_FLOW_PADDING } from './elements'
import { RegularNodeData } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/Graph/elements/RegularNode/RegularNode'
import { FlowNodeType } from '@integration-app/sdk'
import { GEdge, GNode } from './elements/common'

export const HORIZONTAL_SPACE_BETWEEN_NODES = 50
export const VERTICAL_SPACE_BETWEEN_NODES = 80

/**
 * Builds automatic layout for the graph
 *
 * NOTE: this function mutates the nodes and edges directly
 */
export function autoLayout(nodes: Node[], edges: Edge[]) {
  const root = nodes.find((node) => node.type === GraphNodeType.Root)

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  root.position = { x: 0, y: 0 }

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'Node<any, string | undefined> | ... Remove this comment to see the full error message
  positionChildren(root, nodes, edges)

  positionSubFlowRootNodes(nodes, edges)
}

/*
 * Find forEach nodes, their root nodes and position them
 */
function positionSubFlowRootNodes(nodes: Node[], edges: Edge[]) {
  const nodesWithSubFlow = nodes.filter(isNodeWithSubFlow)

  nodesWithSubFlow.forEach((nodeWithSubFlow) => {
    const subFlowRootNode = getSubFlowRootNode(nodeWithSubFlow, nodes)

    if (!subFlowRootNode) {
      return
    }

    const y = nodeWithSubFlow.position.y + SUB_FLOW_PADDING
    const x =
      nodeWithSubFlow.position.x +
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2345): Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
      centerRegion(nodeWithSubFlow.width, subFlowRootNode.width)

    // set position of the sub flow root node
    subFlowRootNode.position = { y, x }

    positionChildren(subFlowRootNode, nodes, edges)
  })
}

/*
 * Position children of the parent node recursively
 */
function positionChildren(parent: Node, nodes: Node[], edges: Edge[]) {
  const children = getOutgoers(parent, nodes, edges)

  if (children.length === 0) {
    // if there are no children, we don't need to position anything
    return
  }

  const { height: parentHeight, width: parentWidth } = getNodeSize(
    parent,
    nodes,
    edges,
  )

  // y coordinate of the children is the y coordinate of the parent plus the height of the parent and spacing between nodes
  const y = parent.position.y + parentHeight + VERTICAL_SPACE_BETWEEN_NODES

  // the starting position of the x coordinate is the x of the parent node minus the centered region between width and downstream nodes graph width
  let x =
    parent.position.x -
    centerRegion(
      getDownstreamNodesGraphWidth(parent, nodes, edges),
      parentWidth,
    )

  // here we increment x for each child, but the calculation is a bit more complex
  children.forEach((child) => {
    const { width } = getNodeSize(child, nodes, edges)
    const downstreamNodesWidth = getDownstreamNodesGraphWidth(
      child,
      nodes,
      edges,
    )

    // to center current node we use region which is max between the downstream nodes width and the parent width (nodes above)
    const centered = centerRegion(
      Math.max(downstreamNodesWidth, parentWidth),
      width,
    )

    x += centered

    // position the child
    child.position = { x, y }

    // recursively position children of the child
    positionChildren(child, nodes, edges)

    // increment x for the next child with subgraph width, space between nodes and subtract the centered region (I can't give a good explanation for this, I did this intuitively -_-)
    x += downstreamNodesWidth + HORIZONTAL_SPACE_BETWEEN_NODES - centered
  })
}

function centerRegion(bigger: number, smaller: number): number {
  return bigger / 2 - smaller / 2
}

/**
 * Returns the width of the downstream nodes graph
 */
export function getDownstreamNodesGraphWidth(
  node: Node,
  nodes: Node[],
  edges: Edge[],
): number {
  const { width } = getNodeSize(node, nodes, edges)

  const children = getOutgoers(node, nodes, edges)

  const childrenWidth = children.reduce((acc, child) => {
    return acc + getDownstreamNodesGraphWidth(child, nodes, edges)
  }, 0)

  return Math.max(
    width,
    childrenWidth + HORIZONTAL_SPACE_BETWEEN_NODES * (children.length - 1),
  )
}

/**
 * Returns the height of the downstream nodes graph
 */
export function getDownstreamNodesGraphHeight(
  node: Node,
  nodes: Node[],
  edges: Edge[],
): number {
  const children = getOutgoers(node, nodes, edges)
  const { height } = getNodeSize(node, nodes, edges)

  if (children.length === 0) {
    return height
  }

  const maxChildrenHeight = children.reduce((acc, child) => {
    const childrenSubgraphHeight = getDownstreamNodesGraphHeight(
      child,
      nodes,
      edges,
    )

    return Math.max(acc, childrenSubgraphHeight)
  }, 0)

  return height + maxChildrenHeight + VERTICAL_SPACE_BETWEEN_NODES
}

/**
 * Returns the size of the node
 */
export function getNodeSize(
  node: Node,
  nodes: Node[],
  edges: Edge[],
): Dimensions {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
  return GRAPH_NODE_SPECS[node.type].getSize(node, nodes, edges)
}

export function getSubFlowRootNode(node: Node<RegularNodeData>, nodes: Node[]) {
  const rootNodeKey = node.data.flowNode.config?.rootNodeKey

  if (!rootNodeKey) {
    return nodes.find(
      (n) =>
        n.type === GraphNodeType.NodePlaceholder &&
        n.data.subgraphParent === node.id,
    )
  }

  return nodes.find(
    (node) => node.data.flowNodeKey === rootNodeKey,
  ) as Node<RegularNodeData>
}

export function getSubFlowNodes(
  nodeWithSubFlow: Node<RegularNodeData>,
  nodes: Node[],
  edges: Edge[],
): Node[] {
  const rootNode = getSubFlowRootNode(nodeWithSubFlow, nodes)

  if (!rootNode) {
    return []
  }

  const nested = getDownstreamNodes(rootNode, nodes, edges)

  return [rootNode, ...nested]
}

export function isNodeWithSubFlow(node: Node): boolean {
  const isForEachV2 = node.data.flowNode?.type === FlowNodeType.ForEachV2

  const isOldForEach =
    node.data.flowNode?.type === FlowNodeType.ForEach &&
    node.data.flowNode?.version === 2

  return isForEachV2 || isOldForEach
}

function getDownstreamNodes(
  node: GNode,
  nodes: GNode[],
  edges: GEdge[],
): GNode[] {
  const nested = getOutgoers(node, nodes, edges)

  const childrenOfNested = nested.map((outGoer) =>
    getDownstreamNodes(outGoer, nodes, edges),
  )

  return [...nested, ...childrenOfNested.flat()]
}
