// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '416a073936e041b7c1841ba767315301';
const css =`._menuButton_1ahfp_1{align-items:center;border-radius:9999px;display:flex;height:1.75rem;justify-content:center;width:1.75rem}._menuButton_1ahfp_1:not(:focus):hover{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity))}._menuButton_1ahfp_1 svg{height:1.25rem;width:1.25rem}._menuButton_1ahfp_1 svg._menuButton_defaultIcon_1ahfp_1{height:1rem;width:1rem}body ._menuPopper_1ahfp_1{padding:0}._menuList_1ahfp_1{border-radius:.25rem}._menuItem_1ahfp_1{--tw-border-opacity:1;border-color:rgb(221 221 221/var(--tw-border-opacity));border-top-width:1px}._menuItem_1ahfp_1:first-child{border-top-width:0}._menuItem_1ahfp_1>*{display:block;height:auto!important;line-height:1!important;margin:0!important;padding:.625rem 1rem!important;text-align:left!important;width:100%!important}._menuItem_1ahfp_1:not([disabled])>*{cursor:pointer}._menuItem_1ahfp_1:not([disabled])>:hover{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity))}._menuItem_1ahfp_1[disabled]{--tw-text-opacity:1;color:rgb(105 105 105/var(--tw-text-opacity))}`;
const styles = {
    get ['menuButton']() { injectStyles(key, css, options);  return '_menuButton_1ahfp_1'; },
    get ['menuButton_defaultIcon']() { injectStyles(key, css, options);  return '_menuButton_defaultIcon_1ahfp_1'; },
    get ['menuButtonDefaultIcon']() { injectStyles(key, css, options);  return '_menuButton_defaultIcon_1ahfp_1'; },
    get ['menuPopper']() { injectStyles(key, css, options);  return '_menuPopper_1ahfp_1'; },
    get ['menuList']() { injectStyles(key, css, options);  return '_menuList_1ahfp_1'; },
    get ['menuItem']() { injectStyles(key, css, options);  return '_menuItem_1ahfp_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
