import {
  excludeFieldsFromSchema,
  pickFieldsFromSchema,
  schemaWithTitle,
  UNIFIED_DATA_MODELS,
  UnifiedDataModel,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { ConfigCard } from 'components/ConfigCard'
import { RadioGroup } from 'components/RadioGroup'
import { toHeaderCase } from 'js-convert-case'
import { Text } from 'ui-kit/text'
import { GenerateFieldMappingButton } from '../../../Blueprints/FieldMappings/FieldMapping/components/GenerateFieldMappingButton'
import { useGenericConfig } from '../contexts/generic-config-context'
import { SharedExportFieldMapping } from './SharedExportFieldMapping'

enum ExportFieldMappingUnitConfigOption {
  CustomUdm = 'custom-udm',
  CustomVariables = 'custom-variables',
  Shared = 'shared',
}

export function UniversalExportFieldMappingUnitConfig({
  isUpdate = false,
}: {
  isUpdate?: boolean
}) {
  const {
    patchConfig,
    fieldMappingConfig,
    patchFieldMappingConfig,
    udm,
    udmSpec,
    variablesSchema,
    editableVariablesSchemaLocators,
    handleAddVariable,
  } = useGenericConfig()

  async function handleSelectOption({
    value,
  }: {
    value: ExportFieldMappingUnitConfigOption
  }) {
    switch (value) {
      case ExportFieldMappingUnitConfigOption.CustomUdm:
        return patchConfig({
          fieldMapping: {
            defaultUnifiedValue:
              fieldMappingConfig.defaultUnifiedValue ??
              fieldMappingConfig?.default ??
              {},
          },
        })
      case ExportFieldMappingUnitConfigOption.CustomVariables:
        return patchConfig({
          fieldMapping: {
            defaultValue: fieldMappingConfig.defaultValue ?? {},
          },
        })
      case ExportFieldMappingUnitConfigOption.Shared:
        return patchConfig({
          fieldMapping: {
            key: null,
          },
        })
    }
  }

  async function handleMappingChange(value) {
    return patchFieldMappingConfig({ defaultUnifiedValue: value })
  }

  let selectedOption = ExportFieldMappingUnitConfigOption.CustomUdm

  if (fieldMappingConfig?.defaultValue !== undefined) {
    selectedOption = ExportFieldMappingUnitConfigOption.CustomVariables
  }

  if (fieldMappingConfig?.key !== undefined) {
    selectedOption = ExportFieldMappingUnitConfigOption.Shared
  }

  const unifiedSchema = isUpdate
    ? getUdmUpdateFields(udmSpec)
    : getUdmCreateFields(udmSpec)

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Fields</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={4}>
        <RadioGroup.Root
          value={selectedOption}
          onValueChange={handleSelectOption}
          gap={1}
        >
          <RadioGroup.Item value={ExportFieldMappingUnitConfigOption.CustomUdm}>
            Provide fields for universal data model{' '}
            {toHeaderCase(udmSpec?.pluralName)}
          </RadioGroup.Item>
          <RadioGroup.Item value={ExportFieldMappingUnitConfigOption.Shared}>
            Use shared field mapping config
          </RadioGroup.Item>
          <RadioGroup.Item
            value={ExportFieldMappingUnitConfigOption.CustomVariables}
          >
            Use application-specific fields from input variables
          </RadioGroup.Item>
        </RadioGroup.Root>

        {selectedOption === ExportFieldMappingUnitConfigOption.Shared && (
          <SharedExportFieldMapping />
        )}

        {selectedOption === ExportFieldMappingUnitConfigOption.CustomUdm && (
          <div>
            {udm ? (
              <>
                {!UNIFIED_DATA_MODELS[udm]?.fieldsSchema ? (
                  <p>UDM "{udm}" does not support creating records.</p>
                ) : (
                  <>
                    <p>
                      These values will be used by default when the integration
                      is enabled. They can be changed later in the User
                      Interface.
                    </p>

                    {unifiedSchema && variablesSchema?.properties?.input && (
                      <GenerateFieldMappingButton
                        fromSchema={variablesSchema}
                        toSchema={unifiedSchema}
                        onGenerate={handleMappingChange}
                      />
                    )}

                    <DataBuilderForm
                      onChange={handleMappingChange}
                      field={makeDataField({
                        schema: schemaWithTitle(
                          unifiedSchema,
                          'Universal Fields',
                        ),
                        variablesSchema: variablesSchema,
                        value:
                          fieldMappingConfig.defaultUnifiedValue ??
                          fieldMappingConfig.default, // fallback to `default` for backward compatibility
                      })}
                      editableVariablesSchemaLocators={
                        editableVariablesSchemaLocators
                      }
                      onAddVariable={handleAddVariable}
                      hideReadOnlyFields
                    />
                  </>
                )}
              </>
            ) : (
              <Text m={0}>
                Please select a data source that has has a Universal Data Model
                (UDM) configured. Alternatively, you can configure fields on
                individual application level.
              </Text>
            )}
          </div>
        )}

        {selectedOption ===
          ExportFieldMappingUnitConfigOption.CustomVariables && (
          <div>
            <DataBuilderForm
              onChange={(defaultValue) =>
                patchFieldMappingConfig({ defaultValue })
              }
              field={makeDataField({
                schema: { title: 'Raw Fields', type: 'object' },
                variablesSchema: variablesSchema,
                value: fieldMappingConfig.defaultValue,
              })}
              editableVariablesSchemaLocators={editableVariablesSchemaLocators}
              onAddVariable={handleAddVariable}
            />
          </div>
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

function getUdmCreateFields(
  udmSpec: UnifiedDataModel | undefined,
): any | undefined {
  if (!udmSpec || !udmSpec.fieldsSchema) {
    return null
  }

  let fieldsSchema = udmSpec.fieldsSchema

  if (udmSpec.create?.fields) {
    fieldsSchema = pickFieldsFromSchema(
      udmSpec.fieldsSchema,
      udmSpec.create.fields,
    )
  } else if (udmSpec.create?.excludedFields) {
    fieldsSchema = excludeFieldsFromSchema(
      udmSpec.fieldsSchema,
      udmSpec.create.excludedFields,
    )
  }

  return fieldsSchema
}

function getUdmUpdateFields(
  udmSpec: UnifiedDataModel | undefined,
): any | undefined {
  if (!udmSpec || !udmSpec.fieldsSchema) {
    return null
  }

  let fieldsSchema = udmSpec.fieldsSchema

  if (udmSpec.update?.fields) {
    fieldsSchema = pickFieldsFromSchema(
      udmSpec.fieldsSchema,
      udmSpec.update.fields,
    )
  } else if (udmSpec.update?.excludedFields) {
    fieldsSchema = excludeFieldsFromSchema(
      udmSpec.fieldsSchema,
      udmSpec.update.excludedFields,
    )
  }

  return fieldsSchema
}
