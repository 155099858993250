import { EdgeProps, getSmoothStepPath, useEdges, useNodes } from 'reactflow'
import { EDGE_HANDLE_OFFSET } from '../common'
import { getNodeSize } from '../../new-layout'
import { DEFAULT_NODE_HEIGHT } from '../index'

export function useEdgePath<Data>(props: EdgeProps<Data>) {
  const {
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    source,
  } = props

  const nodes = useNodes()
  const edges = useEdges()
  const node = nodes?.find((node) => node.id === source)

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'Node<unknown, string | undefined... Remove this comment to see the full error message
  const sourceHeight = getNodeSize(node, nodes, edges).height

  // source Y offset is the source node height minus the default node body height (and 1px for the border)
  const sourceYOffset = sourceHeight - DEFAULT_NODE_HEIGHT - 1

  const shiftedSourceY = sourceY - EDGE_HANDLE_OFFSET + sourceYOffset
  const shiftedTargetY = targetY + EDGE_HANDLE_OFFSET

  // calculating edge center
  const [, centerX, centerY] = getSmoothStepPath({
    sourceX,
    targetX,

    sourceY: shiftedSourceY,
    targetY: shiftedTargetY,
  })

  // calculating curve path with shifted center
  const [path] = getSmoothStepPath({
    sourcePosition,
    targetPosition,

    sourceX,
    targetX,

    sourceY: shiftedSourceY,
    targetY: shiftedTargetY,

    centerX,
    centerY,

    borderRadius: 0,
  })

  return path
}
