import { makeDataField } from '@integration-app/ui'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { ConfigCard } from 'components/ConfigCard'
import { useGenericConfig } from './contexts/generic-config-context'

export function SearchQueryConfig() {
  const { patchConfig, config, variablesSchema } = useGenericConfig()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Search Query</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <DataBuilderForm
          onChange={(query) => patchConfig({ query })}
          field={makeDataField({
            variablesSchema,
            value: config.query,
            schema: { type: 'string', title: 'Query' },
          })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
