import { DocFullPageHeader } from 'routes/Docs/components/DocsLayout/DocFullPageHeader'
import { DocFullPageSidebar } from 'routes/Docs/components/DocsLayout/DocFullPageSidebar'
import { DocFullPageMain } from 'routes/Docs/components/DocsLayout/DocFullPageMain'
import { DocFullPageArticle } from 'routes/Docs/components/DocsLayout/DocFullPageArticle'
import { DocFullPageToc } from 'routes/Docs/components/DocsLayout/DocFullPageToc'
import { DocFullPageContent } from 'routes/Docs/components/DocsLayout/DocFullPageContent'

export {
  DocFullPageHeader,
  DocFullPageSidebar,
  DocFullPageMain,
  DocFullPageContent,
  DocFullPageArticle,
  DocFullPageToc,
}
