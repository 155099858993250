import { ComponentProps } from 'react'
import { ButtonWithSlots } from '../../Button/ButtonWithSlots'
import { VStack } from '../../../styled-system/jsx'
import { Heading } from '../../../ui-kit/heading'
import { Text } from '../../../ui-kit/text'
import { Truncate } from '@integration-app/react'

function ItemButtonRoot({
  children,
  leftSlot,
  ...props
}: ComponentProps<typeof ButtonWithSlots>) {
  return (
    <ButtonWithSlots
      variant={'subtle'}
      lineHeight={'tight'}
      height={'auto'}
      padding={3}
      leftSlot={leftSlot}
      {...props}
    >
      <VStack gap={0} alignItems={'start'} flexGrow={0} flexShrink={1}>
        {children}
      </VStack>
    </ButtonWithSlots>
  )
}

function Header({ children, ...props }: ComponentProps<typeof Heading>) {
  return (
    <Heading as={'h4'} size={'md'} fontWeight={'normal'} margin={0} {...props}>
      <Truncate mode={'end'}>{children}</Truncate>
    </Heading>
  )
}

function Description({ children, ...props }: ComponentProps<typeof Text>) {
  return (
    <Text
      color={'fg.muted'}
      margin={0}
      truncate
      {...props}
      /* Hack to make inner truncate works */
      display={'inline-grid'}
    >
      <Truncate mode={'end'}>{children}</Truncate>
    </Text>
  )
}

const ItemButton = {
  Root: ItemButtonRoot,
  Header: Header,
  Description: Description,
}

export { ItemButton }
