import { pickFieldsFromSchema, schemaWithTitle } from '@integration-app/sdk'
import FieldList from '@integration-app/ui/DataBuilder/FieldList'
import { ConfigCard } from 'components/ConfigCard'
import { Text } from 'ui-kit/text'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { useGenericConfig } from './contexts/generic-config-context'

export function LookupQueryConfig() {
  const {
    variablesSchema,
    config,
    patchConfig,
    udm,
    udmSpec,
    dataCollectionSpec,
    integration,
  } = useGenericConfig()

  const isUniversal = !integration

  const hasUdm = !!udm
  const hasDataCollection = !!dataCollectionSpec
  const universalLookupFields =
    udmSpec?.match?.fields ?? udmSpec?.find?.queryFields ?? []
  const integrationSpecificFields =
    dataCollectionSpec?.match?.fields ??
    dataCollectionSpec?.find?.queryFields ??
    []
  const lookupFields = isUniversal
    ? universalLookupFields
    : integrationSpecificFields

  const fieldsSchema = isUniversal
    ? udmSpec?.fieldsSchema
    : dataCollectionSpec?.fieldsSchema

  const hasLookupFields = lookupFields.length > 0
  const lookupConfig = config?.lookup ?? {}

  const lookupFieldsSchema = schemaWithTitle(
    pickFieldsFromSchema(fieldsSchema, lookupFields),
    'Lookup Fields',
  )

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Lookup Query</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={4}>
        <Text m={0}>
          Set up a default query used to find the record. It can be later
          customized for each connection this integration is applied to.
        </Text>

        {isUniversal ? (
          hasUdm ? (
            hasLookupFields ? (
              <FieldList
                schema={lookupFieldsSchema}
                variablesSchema={variablesSchema}
                value={lookupConfig.universalQuery}
                onChange={(universalQuery) =>
                  patchConfig({
                    lookup: {
                      ...lookupConfig,
                      universalQuery,
                    },
                  })
                }
              />
            ) : (
              <Text m={0} fontStyle={'italic'}>
                Data Model "{udm}" does not have fields to find by.
              </Text>
            )
          ) : (
            <Text m={0} fontStyle={'italic'}>
              You need to select Data Model in the data source configuration to
              configure lookup fields.
            </Text>
          )
        ) : hasDataCollection ? (
          hasLookupFields ? (
            <FieldList
              schema={lookupFieldsSchema}
              variablesSchema={variablesSchema}
              value={lookupConfig.query}
              onChange={(query) =>
                patchConfig({
                  lookup: {
                    ...lookupConfig,
                    query,
                  },
                })
              }
            />
          ) : (
            <Text m={0} fontStyle={'italic'}>
              Data Collection "{dataCollectionSpec?.name}" does not have fields
              to lookup by.
            </Text>
          )
        ) : (
          <Text m={0}>Please configure a data source first.</Text>
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

export const getLookupQueryConfigErrors: NodeConfigurationErrorsGetter = ({
  runTimeVariablesSchema,
  config,
}) => {
  return getBrokenVariablesConfigurationErrors(config?.query, [
    runTimeVariablesSchema,
  ])
}
