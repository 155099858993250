import { SvgIconType } from '@integration-app/ui/SvgIcon'
import {
  FlowInstanceNode,
  FlowNode,
  IntegrationElementType,
} from '@integration-app/sdk'
import { toHeaderCase } from 'js-convert-case'
import { INodeStackItem, NodeStackItemType } from './stack-item'
import { CONSOLE_NODE_SPECS } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/SidePanels/nodes'

export function calculateNodeStackItems(
  node: FlowNode | FlowInstanceNode,
): INodeStackItem[] {
  if ('state' in node) {
    return instanceNodeStackItemsFactory(node)
  } else {
    return builderNodeStackItemsFactory(node)
  }
}

function builderNodeStackItemsFactory(node: FlowNode): INodeStackItem[] {
  if (!node.type) return []
  return (CONSOLE_NODE_SPECS[node.type]?.stackItemTypes ?? [])
    .map((type) => getFactoryByType(type)(node))
    .filter(Boolean)
}

function instanceNodeStackItemsFactory(
  node: FlowInstanceNode,
): INodeStackItem[] {
  const items: INodeStackItem[] = []

  node?.dependencies?.forEach((dependency) => {
    const id = dependency.instanceId

    if (id) {
      const type = getNodeStackItemTypeFromDependencyType(dependency.type)

      if (!type) {
        return
      }

      items.push({
        id,
        type,
        icon: getIcon(type),
        text: `${toHeaderCase(type)}: ${id}`,
      })
    }
  })

  return items
}

export type INodeStackItemFactory = (node: FlowNode) => INodeStackItem | null

const dataSourceStackItemFactory: INodeStackItemFactory = (node) => {
  const key = node.config?.dataSource?.key

  const type = NodeStackItemType.DataSource
  const icon = getIcon(type)

  if (!key) {
    return null
  }

  return {
    id: key,
    type,
    icon,
    text: `Data Source: ${key}`,
  }
}

const fieldMappingStackItemFactory: INodeStackItemFactory = (node) => {
  const key = node.config?.fieldMapping?.key
  const appSchema = node.config?.fieldMapping?.appSchema

  if (!key && !appSchema) {
    return null
  }

  const type = NodeStackItemType.FieldMapping
  const icon = getIcon(type)

  if (!key && appSchema) {
    return {
      id: 'custom-field-mapping',
      type,
      icon,

      text: 'Field Mapping: custom',
    }
  }

  return {
    id: key,
    type,
    icon,

    text: `Field Mapping: ${key}`,
  }
}

const appEventTypeStackItemFactory: INodeStackItemFactory = (node) => {
  const key = node.config?.appEvent?.key

  if (!key) {
    return null
  }

  const type = NodeStackItemType.AppEventType
  const icon = getIcon(type)

  return {
    id: key,
    type,
    icon,

    text: `App Event: ${key}`,
  }
}

const dataLinkTableStackItemFactory: INodeStackItemFactory = (node) => {
  const key = node.config?.dataLinkTable?.key

  if (!key) {
    return null
  }

  const type = NodeStackItemType.DataLinkTable
  const icon = getIcon(type)

  return {
    id: key,
    type,
    icon,

    text: `Data Link Table: ${key}`,
  }
}

function getFactoryByType(type: NodeStackItemType) {
  switch (type) {
    case NodeStackItemType.DataSource:
      return dataSourceStackItemFactory
    case NodeStackItemType.AppEventType:
      return appEventTypeStackItemFactory
    case NodeStackItemType.FieldMapping:
      return fieldMappingStackItemFactory
    case NodeStackItemType.DataLinkTable:
      return dataLinkTableStackItemFactory
    default:
      throw new Error(`Unknown node stack item type: ${type}`)
  }
}

function getIcon(type: NodeStackItemType): SvgIconType {
  switch (type) {
    case NodeStackItemType.DataSource:
      return SvgIconType.DataSourceEntity
    case NodeStackItemType.AppEventType:
      return SvgIconType.AppEventSubscriptionEntity
    case NodeStackItemType.FieldMapping:
      return SvgIconType.FieldMappingEntity
    case NodeStackItemType.DataLinkTable:
      return SvgIconType.Link
    default:
      throw new Error(`Unknown node stack item type: ${type}`)
  }
}

function getNodeStackItemTypeFromDependencyType(
  dependencyType: IntegrationElementType,
): NodeStackItemType {
  switch (dependencyType) {
    case IntegrationElementType.APP_EVENT_TYPE:
      return NodeStackItemType.AppEventType
    case IntegrationElementType.DATA_SOURCE:
      return NodeStackItemType.DataSource
    case IntegrationElementType.FIELD_MAPPING:
      return NodeStackItemType.FieldMapping
    default:
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'NodeStackIt... Remove this comment to see the full error message
      return null
  }
}
