const restApiEndpoints = [
  '/self',
  '/app-event-types',
  '/app-events',
  '/app-event-subscriptions',
  '/app-event-subscription',
  '/app-event-subscription/schema',
  '/users',
  '/app-data-schemas',
  '/app-data-schema-instances',
  '/app-data-schema-instance',
  '/data-source-events',
  '/data-sources',
  '/data-source-instance',
  '/data-source-instance/setup',
  '/data-source-instance/reset',
  '/data-source-instance/resubscribe',
  '/data-source-instance/collection',
  '/data-source-instance/records',
  '/connections',
  '/integrations',
  '/flows',
  '/flow-instances',
  '/flow-instance',
  '/flow-instance/update-to-latest-flow',
  '/flow-instance/reset',
  '/flow-instance/setup',
  '/field-mappings',
  '/field-mapping-instances',
  '/field-mapping-instance',
  '/field-mapping-instance/setup',
  '/field-mapping-instance/reset',
  '/data-link-tables',
  '/data-links',
  '/data-link-table-instances',
  '/data-link-table-instance',
  '/flow-runs',
]

const restApiEndpointLinks = {
  '/self':
    'https://console.integration.app/ref/rest-api/index.html#tag/Auth/operation/Self',
  '/app-event-types':
    'https://console.integration.app/ref/rest-api/index.html#tag/App-Events/operation/List%20App%20Event%20Types',
  '/app-events':
    'https://console.integration.app/ref/rest-api/index.html#tag/App-Events/operation/List%20App%20Events',
  '/app-event-subscriptions':
    'https://console.integration.app/ref/rest-api/index.html#tag/App-Events/operation/List%20App%20Event%20Subscriptions',
  '/app-event-subscription':
    'https://console.integration.app/ref/rest-api/index.html#tag/App-Events/operation/Get%20App%20Event%20Subscription',
  '/app-event-subscription/schema':
    'https://console.integration.app/ref/rest-api/index.html#tag/App-Events/operation/Get%20App%20Event%20Subscription%20Schema',
  '/users':
    'https://console.integration.app/ref/rest-api/index.html#tag/Users/operation/List%20Users',
  '/app-data-schemas':
    'https://console.integration.app/ref/rest-api/index.html#tag/App-Data-Schemas/operation/List%20App%20Data%20Schemas',
  '/app-data-schema-instances':
    'https://console.integration.app/ref/rest-api/index.html#tag/App-Data-Schemas/operation/List%20App%20Data%20Schema%20Instances',
  '/app-data-schema-instance':
    'https://console.integration.app/ref/rest-api/index.html#tag/App-Data-Schemas/operation/Get%20App%20Data%20Schema%20Instance',
  '/data-source-events':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Sources/operation/List%20Data%20Source%20Events',
  '/data-sources':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Sources/operation/List%20Data%20Source%20Instances',
  '/data-source-instance':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Sources/operation/Get%20Data%20Source%20Instance',
  '/data-source-instance/setup':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Sources/operation/Setup%20Data%20Source%20Instance',
  '/data-source-instance/reset':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Sources/operation/Reset%20Data%20Source%20Instance',
  '/data-source-instance/resubscribe':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Sources/operation/Resubscribe%20To%20Data%20Source%20Instance',
  '/data-source-instance/collection':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Sources/operation/Get%20Data%20Source%20Instance%20Collection',
  '/data-source-instance/records':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Sources/operation/List%20Data%20Source%20Instance%20Records',
  '/connections':
    'https://console.integration.app/ref/rest-api/index.html#tag/Connections/operation/List%20Connections',
  '/integrations':
    'https://console.integration.app/ref/rest-api/index.html#tag/Integrations/operation/List%20Integrations',
  '/flows':
    'https://console.integration.app/ref/rest-api/index.html#tag/Flows/operation/List%20Flows',
  '/flow-instances':
    'https://console.integration.app/ref/rest-api/index.html#tag/Flows/operation/List%20Flow%20Instances',
  '/flow-instance':
    'https://console.integration.app/ref/rest-api/index.html#tag/Flows/operation/Get%20Flow%20Instance',
  '/flow-instance/update-to-latest-flow':
    'https://console.integration.app/ref/rest-api/index.html#tag/Flows/operation/Update%20Flow%20Instance%20To%20Latest%20Flow',
  '/flow-instance/reset':
    'https://console.integration.app/ref/rest-api/index.html#tag/Flows/operation/Reset%20Flow%20Instance',
  '/flow-instance/setup':
    'https://console.integration.app/ref/rest-api/index.html#tag/Flows/operation/Setup%20Flow%20Instance',
  '/field-mappings':
    'https://console.integration.app/ref/rest-api/index.html#tag/Field-Mappings/operation/List%20Field%20Mappings',
  '/field-mapping-instances':
    'https://console.integration.app/ref/rest-api/index.html#tag/Field-Mappings/operation/List%20Field%20Mapping%20Instances',
  '/field-mapping-instance':
    'https://console.integration.app/ref/rest-api/index.html#tag/Field-Mappings/operation/Get%20Field%20Mapping%20Instance',
  '/field-mapping-instance/setup':
    'https://console.integration.app/ref/rest-api/index.html#tag/Field-Mappings/operation/Setup',
  '/field-mapping-instance/reset':
    'https://console.integration.app/ref/rest-api/index.html#tag/Field-Mappings/operation/Reset%20Field%20Mapping%20Instance',
  '/data-link-tables':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Links/operation/List%20Data%20Link%20Tables',
  '/data-links':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Links/operation/List%20Data%20Links',
  '/data-link-table-instances':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Links/operation/List%20Data%20Link%20Table%20Instances',
  '/data-link-table-instance':
    'https://console.integration.app/ref/rest-api/index.html#tag/Data-Links/operation/Get%20Data%20Link%20Table%20Instance',
  '/flow-runs':
    'https://console.integration.app/ref/rest-api/index.html#tag/Flows/operation/List%20Flow%20Runs',
}

/**
 *  How to update links here
 *  ========================
 *  Copy JSON from https://console.integration.app/docs-json and paste it into json variable below.
 *  Uncomment the code below and run it in the browser console.
 *  Copy values
 */
// const json = {
//   paths: {},
// }
// const links = Object.keys(json.paths)
//   .filter((path) => {
//     return !path.includes('{')
//   })
//   .reduce((acc, path) => {
//     const method = Object.values(json.paths[path])[0]
//     return { ...acc, [path]: getLink(method.tags[0], method.operationId) }
//   }, {})
// function getLink(tag, operation) {
//   return `https://console.integration.app/ref/rest-api/index.html#tag/${encodeURIComponent(
//     tag.replace(/ /g, '-'),
//   )}/operation/${encodeURIComponent(operation)}`
// }

export { restApiEndpoints, restApiEndpointLinks }
