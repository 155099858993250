import clsx from 'utils/clsx'
import classes from '../NodeBody.module.css'
import { NODE_STACK_ITEM_HEIGHT } from '../../common'
import SvgIcon from '@integration-app/ui/SvgIcon'
import { SvgIconType } from '@integration-app/ui/SvgIcon'
import Truncate from '@integration-app/ui/Truncate'
import { INodeStackItem } from './stack-item'

export function NodeStackItem({
  text,
  hasError,
  icon,
  isBottom = true,
  selected = false,

  onClick,
}: {
  onClick(): void
  selected?: boolean
  isBottom?: boolean
} & INodeStackItem) {
  return (
    <div
      onMouseDownCapture={() => onClick()}
      className={clsx(
        classes.nodeStackItem,
        isBottom ? classes.nodeStackItemBottom : classes.nodeStackItemTop,
        selected && classes.nodeStackItemSelected,
      )}
      style={{
        height: NODE_STACK_ITEM_HEIGHT,
      }}
    >
      {icon && <SvgIcon type={icon} className='w-4 h-4 mr-2' />}

      <Truncate className='flex-1'>{text && text}</Truncate>

      {hasError && <div className={classes.nodeErrorDot} />}
    </div>
  )
}

export function PortalNodeBodyStackItem() {
  return (
    <NodeStackItem
      id={''}
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'NodeStackIt... Remove this comment to see the full error message
      type={null}
      hasError={false}
      isBottom={false}
      selected={false}
      onClick={() => {}}
      text='Go to'
      icon={SvgIconType.ArrowRight}
    />
  )
}
