import { IntegrationElementLevel, buildDataSchema } from '@integration-app/sdk'
import { getBrokenVariablesConfigurationErrors } from '../../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { useGenericConfig } from '../contexts/generic-config-context'
import { ConnectionLevelExportFieldMappingUnitConfig } from './ConnectionLevelExportFieldMappingUnitConfig'
import { IntegrationLevelExportFieldMappingUnitConfig } from './IntegrationLevelExportFieldMappingUnitConfig'
import { UniversalExportFieldMappingUnitConfig } from './UniversalExportFieldMappingUnitConfig'

export function ExportFieldMappingUnitConfig({
  isUpdate,
}: {
  isUpdate?: boolean
}) {
  const { level } = useGenericConfig()

  switch (level) {
    case IntegrationElementLevel.UNIVERSAL:
      return <UniversalExportFieldMappingUnitConfig isUpdate={isUpdate} />
    case IntegrationElementLevel.CONNECTOR:
      return (
        <IntegrationLevelExportFieldMappingUnitConfig isUpdate={isUpdate} />
      )
    case IntegrationElementLevel.CONNECTION:
      return <ConnectionLevelExportFieldMappingUnitConfig isUpdate={isUpdate} />
    default:
      return null
  }
}

export const getExportFieldMappingConfigErrors: NodeConfigurationErrorsGetter =
  ({ node, runTimeVariablesSchema }) => {
    const fieldMappingConfig = node.config?.fieldMapping ?? {}

    if (fieldMappingConfig.key) {
      return []
    }

    const inputErrors = getBrokenVariablesConfigurationErrors(
      fieldMappingConfig.input,
      [runTimeVariablesSchema],
    )

    const inputSchema = fieldMappingConfig.input
      ? buildDataSchema(fieldMappingConfig.input, runTimeVariablesSchema)
      : runTimeVariablesSchema

    const universalFieldsErrors = getBrokenVariablesConfigurationErrors(
      fieldMappingConfig.defaultUnifiedValue ?? fieldMappingConfig.default,
      [inputSchema],
    )

    const integrationSpecificFieldsErrors =
      getBrokenVariablesConfigurationErrors(fieldMappingConfig.defaultValue, [
        inputSchema,
      ])

    return [
      ...inputErrors,
      ...universalFieldsErrors,
      ...integrationSpecificFieldsErrors,
    ]
  }
