import ExampleCodeBlock from '../../Docs/ExampleCodeBlock'

const jsx = `import { IntegrationAppProvider } from '@integration-app/react'
import './styles.css' // include Tailwind Preflight
import '@integration-app/react/styles.css'

export function MyApp() {
  return (
    <IntegrationAppProvider token='{TOKEN}'>
      <MyComponent />
    </IntegrationAppProvider>
  )
}`

export function IntegrationAppProviderStyledCodeExample() {
  return <ExampleCodeBlock customCodes={{ jsx }} />
}
