import classes from './LeafEdge.module.css'
import { AddNodePlusButton } from '../Edge/AddNodePlusButton'
import { getOutgoers, useEdges, useNodes } from 'reactflow'
import { RegularNodeData } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/Graph/elements/RegularNode/RegularNode'
import { TriggerNodeData } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/Graph/elements/TriggerNode/TriggerNode'

export function LeafEdge({
  source,
  nodeHeight,
}: {
  source: string
  nodeHeight: number
}) {
  const nodes = useNodes<RegularNodeData | TriggerNodeData>()
  const edges = useEdges()

  const currentNode = nodes.find(({ id }) => id === source)
  const nodesOut = currentNode ? getOutgoers(currentNode, nodes, edges) : []

  const show = nodesOut.length === 0

  if (!show) {
    return null
  }

  return (
    <div
      className={classes.leafEdge}
      style={{
        top: nodeHeight + 4,
      }}
    >
      <div className={classes.leafEdgeLine} />

      <AddNodePlusButton parent={source} child={''} linkIndex={0} />
    </div>
  )
}
