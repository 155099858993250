import { makeDataField } from '@integration-app/ui/DataBuilder'
import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { CollapsibleSection } from '../Section'
import { DataLinkTableConfigWithCreatePopup } from './DataLinkTableConfigWithCreatePopup'
import { useGenericConfig } from './contexts/generic-config-context'

export function FindOrCreateDataRecordDataLinkConfig() {
  const { dataLinkConfig, patchDataLinkConfig, variablesSchema } =
    useGenericConfig()

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Data Link</CollapsibleSection.Header>

      <p>Select Data Link Table and App Record Id data to find the link</p>

      <DataLinkTableConfigWithCreatePopup
        dataLinkTableKey={dataLinkConfig?.dataLinkTableKey}
        onChange={(dataLinkTableKey) =>
          patchDataLinkConfig({ dataLinkTableKey })
        }
      />

      <AdminDataBuilderForm
        onChange={(appRecordId) => patchDataLinkConfig({ appRecordId })}
        field={makeDataField({
          schema: {
            type: 'string',
            title: 'App Record ID',
          },
          value: dataLinkConfig.appRecordId,
          variablesSchema,
        })}
      />
    </CollapsibleSection>
  )
}

export const getFindOrCreateDataRecordDataLinkConfigErrors: NodeConfigurationErrorsGetter =
  ({ runTimeVariablesSchema, config }) => {
    return getBrokenVariablesConfigurationErrors(config?.dataLink, [
      runTimeVariablesSchema,
    ])
  }
