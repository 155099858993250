import { useState } from 'react'
import Fuse from 'fuse.js'
import {
  SearchInput as SearchInputComponent,
  SearchInputProps as SearchInputComponentProps,
} from '@integration-app/ui/Input'
import { useDebounce } from 'use-debounce'
import { useDebounceCallback } from 'usehooks-ts'

interface ISearchInput extends SearchInputComponentProps {
  onChange: (value) => void
}

export function SearchInput({
  value,
  onChange,
  placeholder,
  autoFocus,
  className,
  disabled,
  ...props
}: ISearchInput) {
  const handleChange = (e) => onChange(e.target.value)

  return (
    <SearchInputComponent
      variant={'background'}
      value={value}
      autoFocus={autoFocus}
      disabled={disabled}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
      {...props}
    ></SearchInputComponent>
  )
}

export function DebouncedSearchInput({
  debounce = 300,
  value,
  onChange,
  ...props
}: { debounce?: number } & ISearchInput) {
  const [localValue, setLocalValue] = useState(value)
  const debouncedOnChange = useDebounceCallback(onChange, debounce)
  function handleOnChange(newValue) {
    setLocalValue(newValue)
    debouncedOnChange(newValue)
  }
  return <SearchInput onChange={handleOnChange} value={localValue} {...props} />
}

export function filterItemsBySearch<T>(
  items: T[],
  searchValue: string,
  keys: string[],
  threshold = 0.6,
): T[] {
  if (!searchValue) return items

  return new Fuse(items, {
    shouldSort: true,
    threshold,
    keys,
  })
    .search(searchValue.trim())
    .map(({ item }) => item)
}

export function useSearch<Item>(
  items: Item[],
  searchByFields: string[],
  threshold?: number,
) {
  const [search, setSearch] = useState('')

  const filtered = filterItemsBySearch(items, search, searchByFields, threshold)

  return {
    filtered,
    search,
    setSearch,
  }
}

export function useDebouncedSearch(delay = 300) {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, delay)

  return {
    search,
    debouncedSearch,
    setSearch,
  }
}
