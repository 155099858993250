import { WorkspaceElementState } from '@integration-app/sdk'

export function WorkspaceElementStateLabel({
  state,
}: {
  state: WorkspaceElementState
}) {
  const color = getColorByState(state)
  const text = getTextByState(state)
  const classes = color + ' bg-opacity-60 py-2 px-4 rounded text-white'

  return <span className={classes}>{text}</span>
}

function getTextByState(state: WorkspaceElementState) {
  switch (state) {
    case WorkspaceElementState.READY:
      return 'Ready'
    case WorkspaceElementState.SETTING_UP:
      return 'Setting Up...'
    case WorkspaceElementState.SETUP_FAILED:
      return 'Failed'
    default:
      return ''
  }
}

function getColorByState(state: WorkspaceElementState) {
  switch (state) {
    case WorkspaceElementState.READY:
      return 'bg-success'
    case WorkspaceElementState.SETTING_UP:
      return 'bg-warning'
    case WorkspaceElementState.SETUP_FAILED:
      return 'bg-error'
    default:
      return ''
  }
}
