import { useRef } from 'react'
import classes from './Edge.module.css'
import { FlowNodeLink } from '@integration-app/sdk'
import { NODE_WIDTH } from '../common'

import { toSnakeCase } from 'js-convert-case'
import clsx from 'utils/clsx'
import { useNavigate } from 'react-router-dom'
import { useFlowBuilder } from 'routes/Workspaces/Workspace/components/FlowBuilder/flow-builder-context'

export function LinkNameOnEdgeCenter({
  x,
  y,
  link,
  nodeKey,
  interactive = true,
}: {
  x: number
  y: number
  link: FlowNodeLink
  nodeKey: string
  interactive?: boolean
}) {
  const ref = useRef<HTMLSpanElement>()

  const spanHeight = 20

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const rename = useRenameLink(nodeKey, link?.name)

  if (!link?.name) {
    return null
  }

  const truncatedName =
    link.name.length > 16 ? `${link.name.slice(0, 16)}...` : link.name

  return (
    <foreignObject
      width={NODE_WIDTH / 2}
      height={spanHeight + 2}
      x={x - NODE_WIDTH / 4}
      y={y - spanHeight / 2}
      requiredExtensions='http://www.w3.org/1999/xhtml'
      style={{
        display: 'flex',
      }}
    >
      <span
        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error TS(2322): Type 'MutableRefObject<HTMLSpanElement | undefined... Remove this comment to see the full error message
        ref={ref}
        onClick={interactive ? rename : () => {}}
        className={clsx(
          classes.edgeLinkName,
          interactive && classes.edgeLinkNameHoverState,
        )}
      >
        {truncatedName}
      </span>
    </foreignObject>
  )
}

export function useRenameLink(nodeKey: string, linkName: string) {
  const navigate = useNavigate()
  const { baseRoute } = useFlowBuilder()

  return async () => {
    navigate(`${baseRoute}/nodes/${nodeKey}/links`)

    // wait 100ms for page to render the input
    await new Promise((resolve) => setTimeout(resolve, 100)).then(() => {
      const input = document?.querySelector(
        '#' + getInputIdForLinkName(linkName),
      ) as HTMLInputElement

      input?.select?.()
    })
  }
}

export function getInputIdForLinkName(linkName: string) {
  return toSnakeCase('link-' + linkName || 'default')
}
