import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { ToggleOnOff, ToggleProps } from '@integration-app/ui/Toggle'
import { Children, PropsWithChildren } from 'react'
import classes from './CollapsibleSection.module.css'
import {
  CollapsibleSectionProvider,
  useCollapsibleSection,
} from './CollapsibleSectionContext'

function CollapsibleSectionContainer({
  children,
  open,
}: PropsWithChildren<{ open?: boolean }>) {
  const header = pickChildrenByType(children, [CollapsibleSectionHeader])
  const actionBar = pickChildrenByType(children, [CollapsibleSectionActionBar])
  const restChildren = omitChildrenByType(children, [
    CollapsibleSectionHeader,
    CollapsibleSectionActionBar,
  ])

  return (
    <CollapsibleSectionProvider open={open}>
      <div className={classes.container}>
        <Header>
          <Label>{header}</Label>
          <ActionBar>{actionBar}</ActionBar>
        </Header>
        <Content>{restChildren}</Content>
      </div>
    </CollapsibleSectionProvider>
  )
}
CollapsibleSectionContainer.displayName = 'CollapsibleSection'

function CollapsibleSectionHeader({ children }) {
  return <>{children}</>
}
CollapsibleSectionHeader.displayName = 'CollapsibleSection.Header'

function CollapsibleSectionActionBar({ children }) {
  return <div className={classes.collapsibleSection_container}>{children}</div>
}
CollapsibleSectionActionBar.displayName = 'CollapsibleSection.ActionBar'

function Header({ children }) {
  return <div className={classes.header}>{children}</div>
}

function Label({ children }) {
  const { setIsOpen, isOpen } = useCollapsibleSection()
  function toggleOpen() {
    setIsOpen(!isOpen)
  }
  return (
    <button className={classes.header_label} onClick={toggleOpen}>
      {children}
      <SvgIcon
        type={isOpen ? SvgIconType.ChevronDown : SvgIconType.ChevronRight}
        className={classes.header_labelIcon}
      />
    </button>
  )
}

function ActionBar({ children }) {
  return <div className={classes.header_actionBar}>{children}</div>
}

function Content({ children }) {
  const { isOpen } = useCollapsibleSection()
  if (!isOpen) {
    return null
  }
  return <div className={classes.content}>{children}</div>
}

export const CollapsibleSection = Object.assign(CollapsibleSectionContainer, {
  Header: CollapsibleSectionHeader,
  ActionBar: CollapsibleSectionActionBar,
})

export function ActionBarToggleOnOff(props: ToggleProps) {
  // @ts-ignore
  return <ToggleOnOff {...props} />
}

function pickChildrenByType(children, types: any[]) {
  // Get display names of the components
  const componentsDisplayNames = types.map((type) =>
    getFunctionName(type, { strict: true }),
  )

  return Children.toArray(children).filter((child: any) => {
    const childType = getChildrenName(child)
    return componentsDisplayNames.includes(childType)
  })
}

function omitChildrenByType(children, types: any[]) {
  const typeNames = types.map((type) => getFunctionName(type))
  return Children.toArray(children).filter((child: any) => {
    const childType = getChildrenName(child)
    return !typeNames.includes(childType)
  })
}

function getFunctionName(fn, { strict }: { strict?: boolean } = {}) {
  if (!strict) {
    return fn?.displayName ?? fn?.name
  }

  if ('displayName' in fn) {
    return fn.displayName
  } else {
    throw new Error('Please set displayName for the component')
  }
}

function getChildrenName(children) {
  return getFunctionName(children?.type)
}
