import { ImgHTMLAttributes, PropsWithChildren, useState } from 'react'
import clsx from 'utils/clsx'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import { Popup } from 'routes/components/Popup'
import classes from './DocImage.module.css'

export default function DocImage({
  className,
  alt,
  title,
  border,
  zoom,
  maxWidth,
  style,
  ...props
}: ImgHTMLAttributes<HTMLImageElement> & {
  border?: boolean
  zoom?: boolean | string
  maxWidth?: number
}) {
  const caption = title || alt
  const imgStyle = (maxWidth || style) && { maxWidth, ...(style || {}) }

  const zoomSrc = typeof zoom === 'string' ? zoom : props.src

  return (
    <ImageWithCaption caption={caption}>
      <ImageWithZoom zoomSrc={zoom ? zoomSrc : undefined}>
        <img
          className={clsx(
            classes.image,
            border && classes.image__border,
            caption && classes.image__caption,
            zoom && classes.image__zoom,
            className,
          )}
          alt={alt}
          title={title}
          {...props}
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type '0 | { accentColor?: AccentColor | undefined;... Remove this comment to see the full error message
          style={imgStyle}
        />
      </ImageWithZoom>
    </ImageWithCaption>
  )
}

function ImageWithZoom({
  zoomSrc,
  children,
}: PropsWithChildren<{ zoomSrc?: string }>) {
  const [open, setOpen] = useState(false)

  const handleClick = useEventStopPropagation((e) => {
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    e.preventDefault()
    setOpen(true)
  })

  const handleClose = () => setOpen(false)

  if (zoomSrc) {
    return (
      <>
        <a href={zoomSrc} onClick={handleClick}>
          {children}
        </a>
        <Popup
          className={classes.popup}
          size={'custom'}
          open={open}
          onClose={handleClose}
        >
          <div className={classes.popup_content}>
            <img src={zoomSrc} alt={''} className={classes.image__border} />
          </div>
        </Popup>
      </>
    )
  }

  return <>{children}</>
}

function ImageWithCaption({
  caption,
  children,
}: PropsWithChildren<{ caption?: string }>) {
  if (caption) {
    return (
      <figure className={classes.figure}>
        {caption && (
          <figcaption className={classes.caption}>{caption}</figcaption>
        )}
        {children}
      </figure>
    )
  }

  return <>{children}</>
}
