import { Portal } from '@ark-ui/react'
import { TbX } from 'react-icons/tb'
import { Popup as UiKitPopup } from 'ui-kit/popup'
import type { ComponentProps } from 'styled-system/types'
import { IconButton } from 'ui-kit/icon-button'

function Root({ children, ...props }: ComponentProps<typeof UiKitPopup.Root>) {
  return (
    <UiKitPopup.Root closeOnInteractOutside={false} {...props}>
      {children}
    </UiKitPopup.Root>
  )
}

function Window({
  children,
  portalled,
  ...props
}: ComponentProps<typeof UiKitPopup.Window> & { portalled?: boolean }) {
  return (
    <Portal disabled={!portalled}>
      <UiKitPopup.Backdrop />
      <UiKitPopup.Positioner>
        <UiKitPopup.Window
          display='grid'
          gridTemplateAreas='"header" "content" "footer"'
          gridTemplateRows='min-content 1fr min-content'
          gap={0}
          {...props}
        >
          <UiKitPopup.CloseTrigger
            asChild
            position='absolute'
            top='3.5'
            right='3'
          >
            <IconButton aria-label='Close Dialog' variant='ghost' size='xs'>
              <TbX />
            </IconButton>
          </UiKitPopup.CloseTrigger>
          {children}
        </UiKitPopup.Window>
      </UiKitPopup.Positioner>
    </Portal>
  )
}

function Header({
  children,
  ...props
}: ComponentProps<typeof UiKitPopup.Header>) {
  return (
    <UiKitPopup.Header
      gridArea='header'
      paddingInline='5'
      paddingBlockStart='4'
      paddingBlockEnd='3'
      {...props}
    >
      {children}
    </UiKitPopup.Header>
  )
}

function Content({
  children,
  ...props
}: ComponentProps<typeof UiKitPopup.Content>) {
  return (
    <UiKitPopup.Content
      gridArea='content'
      overflowY='auto'
      paddingInline='5'
      paddingBlock='4'
      {...props}
    >
      {children}
    </UiKitPopup.Content>
  )
}

function Footer({
  children,
  ...props
}: ComponentProps<typeof UiKitPopup.Footer>) {
  return (
    <UiKitPopup.Footer
      gridArea='footer'
      paddingInline='5'
      paddingBlockStart='3'
      paddingBlock='4'
      {...props}
    >
      {children}
    </UiKitPopup.Footer>
  )
}

const Popup = {
  Root,
  Window,
  Header,
  Content,
  Footer,
  Trigger: UiKitPopup.Trigger,
  CloseTrigger: UiKitPopup.CloseTrigger,
  Title: UiKitPopup.Title,
  Description: UiKitPopup.Description,
}
export { Popup }
