import {
  UNIFIED_DATA_MODELS,
  schemaWithTitle,
  walkSchema,
} from '@integration-app/sdk'
import {
  FieldDescription,
  makeDataField,
} from '@integration-app/ui/DataBuilder'
import { ConfigCard } from 'components/ConfigCard'
import { toHeaderCase } from 'js-convert-case'
import AdminDataBuilderForm from '../../../../../../../components/AdminDataBuilderForm'
import { useFieldMappingContext } from '../contexts/field-mapping-context'
import { GenerateFieldMappingButton } from './GenerateFieldMappingButton'

export function ImportFieldMappingConfig() {
  const {
    fieldMapping,
    isUniversal,
    appSchema: materializedAppSchema,
    onChange,
    dataSource,
    dataCollectionSpec,
  } = useFieldMappingContext()

  // Allow expanding any objects in the schema to simplify editing mapping
  const schema = materializedAppSchema
    ? JSON.parse(
        JSON.stringify(
          schemaWithTitle(materializedAppSchema, 'Internal Fields'),
        ),
      )
    : undefined

  walkSchema(schema, (schema) => {
    if (schema.type === 'object') {
      schema.additionalProperties = true
    }
    return schema
  })

  const udm = dataSource?.udm ? UNIFIED_DATA_MODELS[dataSource?.udm] : undefined

  const universalFieldsSchema = udm?.fieldsSchema

  const fromSchema = isUniversal
    ? schemaWithTitle(
        universalFieldsSchema,
        `Universal ${toHeaderCase(udm?.singularName)} Fields`,
      )
    : schemaWithTitle(dataCollectionSpec?.fieldsSchema, 'External Fields')
  const value = isUniversal
    ? fieldMapping.defaultImportValue
    : fieldMapping.importValue

  const toSchema = schemaWithTitle(
    schema ?? { type: 'object' },
    'Internal Fields',
  )

  const field = makeDataField({
    schema: toSchema,
    variablesSchema: fromSchema,
    value,
  })

  async function handleChange(newValue: any) {
    if (isUniversal) {
      return onChange({
        defaultImportValue: newValue,
      })
    } else {
      return onChange({ importValue: newValue })
    }
  }

  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        rightSlot={
          toSchema &&
          fromSchema && (
            <GenerateFieldMappingButton
              fromSchema={fromSchema}
              toSchema={toSchema}
              onGenerate={handleChange}
            />
          )
        }
      >
        <ConfigCard.Title>Mapping</ConfigCard.Title>
        <FieldDescription
          text={'Map external fields to fields in your application'}
        />
      </ConfigCard.Header>
      <ConfigCard.Content>
        <AdminDataBuilderForm field={field} onChange={handleChange} />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
