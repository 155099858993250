import { useGenericConfig } from './contexts/generic-config-context'
import { CustomizeImplementation } from './CustomizeImplementation'
import { DataCollectionMethodParametersConfig } from './DataCollectionMethodParametersConfig'
import { DataSourceUnitConfig } from './DataSourceUnitConfig'
import { ImportFieldMappingUnitConfig } from './ImportFieldMappingUnitConfig'
import { PaginationConfig } from './PaginationConfig'
import { SearchQueryConfig } from './SearchQueryConfig'

export function SearchDataRecordsUnitConfig() {
  const { dataCollectionSpec } = useGenericConfig()

  const apiRequests = dataCollectionSpec?.search?.apiRequests

  return (
    <>
      <DataSourceUnitConfig />
      <DataCollectionMethodParametersConfig methodKey='search' />
      <PaginationConfig />
      <SearchQueryConfig />
      <CustomizeImplementation apiRequests={apiRequests} />
      <ImportFieldMappingUnitConfig />
    </>
  )
}
