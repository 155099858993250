import React, { AllHTMLAttributes, ReactNode } from 'react'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import clsx from 'utils/clsx'
import { Link } from 'react-router-dom'

import { getSlug } from 'utils/slug'

import classes from './DocHeadings.module.css'

const createDocHeading =
  (level: number) =>
  ({
    className,
    children,
    id,
    ...props
  }: AllHTMLAttributes<HTMLHeadingElement> & { children?: ReactNode }) => {
    const ref = React.useRef<HTMLHeadingElement>()

    const HeadingTag = 'h' + level

    const anchor = id || extractSlugFromChildren(children)

    return (
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type '{ children: (ReactNode | Element)[]; classNa... Remove this comment to see the full error message
      <HeadingTag
        id={anchor}
        {...props}
        className={clsx(classes.header, className)}
        ref={ref}
      >
        <Link to={'#' + anchor} className={classes.anchor}>
          <SvgIcon type={SvgIconType.Link} />
        </Link>

        {children}
      </HeadingTag>
    )
  }

function extractSlugFromChildren(children: ReactNode) {
  return getSlug(extractTextFromChildren(children))
}

function extractTextFromChildren(children: ReactNode) {
  if (typeof children === 'string') {
    return children
  }

  if (Array.isArray(children)) {
    return children.map(extractTextFromChildren).join('')
  }

  return ''
}

export const DocH1 = createDocHeading(1)
export const DocH2 = createDocHeading(2)
export const DocH3 = createDocHeading(3)
export const DocH4 = createDocHeading(4)
export const DocH5 = createDocHeading(5)
export const DocH6 = createDocHeading(6)
