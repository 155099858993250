import { useIntegration } from '@integration-app/react'
import { IconButton } from 'ui-kit/icon-button'
import { CreateSmthPopup } from 'components/Popup/CreateSmthPopup'
import { TbChevronLeft } from 'react-icons/tb'
import { Tooltip } from 'components/Tooltip'
import { PropsWithChildren } from 'react'

export function CreateDataSourcePopupTitle({
  children,
  integrationId,
  onBack,
}: PropsWithChildren<{
  integrationId?: string
  onBack?(): void
}>) {
  const leftSlot = onBack ? (
    <Tooltip
      tooltip={'Back'}
      positioning={{
        placement: 'right',
      }}
    >
      <IconButton
        colorPalette={'primary'}
        variant={'ghost'}
        onClick={onBack}
        size={'xs'}
        marginInlineStart={-3}
      >
        <TbChevronLeft />
      </IconButton>
    </Tooltip>
  ) : null

  return (
    <CreateSmthPopup.Title leftSlot={leftSlot}>
      {children || <CreateDataSourceTitle integrationId={integrationId} />}
    </CreateSmthPopup.Title>
  )
}

function CreateDataSourceTitle({ integrationId }: { integrationId?: string }) {
  const { integration } = useIntegration(integrationId)
  if (!integrationId) {
    return <>Create Universal Data Source</>
  }

  if (!integration?.name) {
    return <>Create Data Source</>
  }

  return <>Create Data Source for {integration?.name}</>
}
