import classes from './styles.module.css'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import Button from '@integration-app/ui/Button'
import clsx from 'utils/clsx'

export function GearHalfButton({
  onClick,
  className,
  disabled = false,
  ...props
}: {
  onClick()
  disabled?: boolean
  className?: string
}) {
  return (
    <Button
      variant='secondary'
      disabled={disabled}
      onClick={onClick}
      className={clsx(className, classes.gearHalfButton)}
      {...props}
    >
      <SvgIcon type={SvgIconType.Settings} />
    </Button>
  )
}
