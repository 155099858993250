import clsx from 'utils/clsx'
import { Link as RouterLink } from 'react-router-dom'

import classes from 'routes/Docs/components/ArticleToc/ArticleToc.module.css'
import {
  ActiveHeaderProps,
  ArticleActiveHeaderIdProvider,
  useArticleActiveHeaderId,
} from 'routes/components/ArticleActiveHeader'
import { getSlug, getSlugger } from 'utils/slug'
import { TbMenu2 } from 'react-icons/tb'
import { DocArticleType, TOCLink } from 'routes/Docs/types'

export function ArticleToc({
  article,
  scrollableContainerSelector,
  articleContainerId,
}: ActiveHeaderProps & {
  article?: DocArticleType
}) {
  const toc = article?.toc

  if (!toc || toc?.length === 0) return null

  // Do not show H1 in TOC
  const renderedLinks = addIdToToc(toc[0].depth === 1 ? toc[0].children : toc)

  return (
    <ArticleActiveHeaderIdProvider
      articleContainerId={articleContainerId}
      scrollableContainerSelector={scrollableContainerSelector}
    >
      <div className={classes.toc}>
        <Links links={renderedLinks} />
      </div>
    </ArticleActiveHeaderIdProvider>
  )
}

function Links({ links, level = 0 }: { links?: TOCLink[]; level?: number }) {
  if (!links || links.length === 0) return null
  return (
    <ul>
      {level === 0 && (
        <li>
          <ArticleTocHeader />
        </li>
      )}
      {links.map((link, id) => (
        <Link key={id} link={link} level={level} />
      ))}
    </ul>
  )
}

function Link({ link, level = 0 }: { link: TOCLink; level?: number }) {
  const activeHeaderId = useArticleActiveHeaderId()
  const slug = link?.slug || getSlug(link.value)

  const isActiveLink = activeHeaderId === slug

  return (
    <li>
      <RouterLink
        to={`#${slug}`}
        className={clsx(classes.tocItem)}
        data-active={isActiveLink}
      >
        {link.value}
      </RouterLink>
      <Links level={level + 1} links={link.children} />
    </li>
  )
}

function addIdToToc(toc: TOCLink[], slugger = getSlugger()) {
  return toc.map((link) => {
    const slug = slugger.slug(link.value)

    return {
      ...link,
      value: link.value,
      slug,
      children: addIdToToc(link.children, slugger),
    }
  })
}

export function ArticleTocStylesForMarkdownToc({ children }) {
  return <div className={classes.toc}>{children}</div>
}

export function ArticleTocHeader() {
  return (
    <h3 className={classes.tocItem__header}>
      <TbMenu2 />
      On this page
    </h3>
  )
}
