import { MenuItem, MenuPopover } from '@integration-app/ui/Menu'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { useRef, useState } from 'react'
import { CodeExampleType } from '../../Docs/codeBlock-types'

export function CodeLanguageSelector({
  value,
  codeExamples,
  onChange,
}: {
  value?: CodeExampleType['language']
  codeExamples: CodeExampleType[]
  onChange: (value: CodeExampleType['language']) => void
}) {
  const [open, setOpen] = useState(false)
  const buttonElement = useRef<HTMLButtonElement>(null)

  function handleClick(codeExample: CodeExampleType) {
    onChange(codeExample.language)
    setOpen(false)
  }

  const title = codeExamples.find(
    (codeExample) => codeExample.language === value,
  )?.title

  if (codeExamples.length === 1) {
    return (
      <button disabled className={'flex flex-row items-center gap-1 px-2 py-1'}>
        {title || 'Select language'}
      </button>
    )
  }

  return (
    <>
      <button
        className={'flex flex-row items-center gap-1 px-2 py-1'}
        ref={buttonElement}
        onClick={() => setOpen((v) => !v)}
      >
        {title || 'Select language'}
        <SvgIcon
          type={SvgIconType.ChevronDown}
          className={'w-4 h-4 stroke-1 mt-0.5'}
        />
      </button>
      <MenuPopover
        refElement={buttonElement}
        isOpen={open}
        onClickOutside={() => setOpen(false)}
        offset={[0, 4]}
      >
        {codeExamples.map((codeExample) => (
          <MenuItem key={codeExample.code}>
            <button onClick={() => handleClick(codeExample)}>
              {codeExample.title}
            </button>
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  )
}
