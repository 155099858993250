import { NodeProps } from 'reactflow'
import {
  SourceHandle,
  TargetHandle,
} from 'components/FlowBuilder/Graph/elements/Handle/Handle'
import { AddTriggerButton } from './AddTriggerButton'
import { PlaceholderNodeBody } from '../NodeOrLinkPlaceholderNode/PaceholderNodeBody/PlaceholderNodeBody'
import { NodeBodyContainer } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBodyContainer'
import { NodeBodyIcon } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBody'
import classes from '../styles.module.css'
import { NodeStats } from '../RegularNode/RegularNode'

export interface TriggerPlaceholderNodeData {
  child?: string
}

export function TriggerPlaceholderNode(
  props: NodeProps<TriggerPlaceholderNodeData>,
) {
  const { child } = props.data

  return (
    <>
      {/* FIXME: strictNullCheck temporary fix */}
      {/* @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message*/}
      <TriggerPlaceholderNodeWithAddButton child={child} />
      <NodeStats props={props} />
    </>
  )
}

function TriggerPlaceholderNodeWithAddButton({ child }: { child: string }) {
  return (
    <NodeBodyContainer>
      <TargetHandle />

      <PlaceholderNodeBody>
        <NodeBodyIcon
          icon={TRIGGER_PLACEHOLDER_NODE_ICON}
          className={classes.triggerPlaceholderNodeIcon}
        />
        <AddTriggerButton child={child} />
      </PlaceholderNodeBody>

      <SourceHandle />
    </NodeBodyContainer>
  )
}

const TRIGGER_PLACEHOLDER_NODE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke="#696969" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path d="M13 3v7h6l-8 11v-7H5l8-11"/>
</svg>`
