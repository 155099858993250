import { Tooltip } from 'components/Tooltip'
import { ComponentProps, forwardRef, ReactElement, ReactNode } from 'react'
import { sva } from 'styled-system/css'
import { Icon } from 'ui-kit/icon'
import * as StyledSwitch from 'ui-kit/styled/switch'
import { SwitchProps } from 'ui-kit/switch'

type CustomSwitchWithIconProps = {
  icon?: ReactElement
  iconOn?: never
  iconOff?: never
}
type CustomSwitchWithIconsProps = {
  icon?: never
  iconOn: ReactElement
  iconOff: ReactElement
}

type ChildrenNotTooltipProps = {
  children: ReactNode
  tooltip?: never
}

type TooltipNotChildrenProps = {
  children?: never
  tooltip: ReactNode
}

type CustomSwitchProps = CustomSwitchWithIconProps | CustomSwitchWithIconsProps

export type ChildrenOrTooltipProps =
  | ChildrenNotTooltipProps
  | TooltipNotChildrenProps

const recipe = sva({
  className: 'switchWithIcon',
  slots: ['thumb', 'iconOn', 'iconOff'],
  base: {
    thumb: {
      display: 'grid',
      gridTemplateAreas: '"icon"',
      gridTemplateColumns: 1,
      gridTemplateRows: 1,
      p: 0.5,
    },
    iconOn: {
      gridArea: 'icon',
      width: 'full',
      height: 'full',
      opacity: 0,
      '[data-state="checked"] &': {
        opacity: 1,
      },
    },
    iconOff: {
      gridArea: 'icon',
      width: 'full',
      height: 'full',
      opacity: 1,
      '[data-state="checked"] &': {
        opacity: 0,
      },
    },
  },
})

export const Switch = forwardRef<
  HTMLLabelElement,
  Omit<SwitchProps, 'children'> & CustomSwitchProps & ChildrenOrTooltipProps
>((props, ref) => {
  const { children, tooltip, iconOn, iconOff, icon, ...rootProps } = props
  const classes = recipe()

  const iconOnElement = iconOn || icon
  const iconOffElement = iconOff || icon

  return (
    <StyledSwitch.Root ref={ref} {...rootProps}>
      <SwitchControl tooltip={tooltip}>
        <StyledSwitch.Thumb className={classes.thumb}>
          {iconOnElement && (
            <Icon className={classes.iconOn}>{iconOnElement}</Icon>
          )}
          {iconOffElement && (
            <Icon className={classes.iconOff}>{iconOffElement}</Icon>
          )}
        </StyledSwitch.Thumb>
      </SwitchControl>
      {children && <StyledSwitch.Label>{children}</StyledSwitch.Label>}
      <StyledSwitch.HiddenInput />
    </StyledSwitch.Root>
  )
})

Switch.displayName = 'Switch'

export function SwitchControl({
  children,
  tooltip,
  ...props
}: ComponentProps<typeof StyledSwitch.Control> & { tooltip?: ReactNode }) {
  if (tooltip) {
    return (
      <Tooltip tooltip={tooltip}>
        <StyledSwitch.Control {...props}>{children}</StyledSwitch.Control>
      </Tooltip>
    )
  }
  return <StyledSwitch.Control {...props}>{children}</StyledSwitch.Control>
}
