import {
  getDataCollectionCreateFields,
  getDataCollectionUpdateFields,
  schemaWithTitle,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { ConfigCard } from 'components/ConfigCard'
import { RadioGroup } from 'components/RadioGroup'
import { VStack } from 'styled-system/jsx'
import { Text } from 'ui-kit/text'
import { GenerateFieldMappingButton } from '../../../Blueprints/FieldMappings/FieldMapping/components/GenerateFieldMappingButton'
import { useGenericConfig } from '../contexts/generic-config-context'
import { SharedExportFieldMapping } from './SharedExportFieldMapping'

const DEFAULT_SCHEMA = {
  type: 'object',
  additionalProperties: true,
}

enum ExportFieldMappingUnitConfigOption {
  Custom = 'custom',
  Shared = 'shared',
}

export function IntegrationLevelExportFieldMappingUnitConfig({
  isUpdate = false,
}: {
  isUpdate?: boolean
}) {
  const {
    patchConfig,
    fieldMappingConfig,
    patchFieldMappingConfig,
    dataCollectionSpec,
    variablesSchema,
    editableVariablesSchemaLocators,
    handleAddVariable,
  } = useGenericConfig()

  async function handleSelectOption({
    value,
  }: {
    value: ExportFieldMappingUnitConfigOption
  }) {
    switch (value) {
      case ExportFieldMappingUnitConfigOption.Custom:
        return patchConfig({
          fieldMapping: {
            defaultValue: fieldMappingConfig.defaultValue,
          },
        })
      case ExportFieldMappingUnitConfigOption.Shared:
        return patchConfig({
          fieldMapping: {
            key: null,
          },
        })
    }
  }

  async function handleMappingChange(value: any) {
    return patchFieldMappingConfig({
      defaultValue: value,
    })
  }

  let selectedOption = ExportFieldMappingUnitConfigOption.Custom

  if (fieldMappingConfig?.key !== undefined) {
    selectedOption = ExportFieldMappingUnitConfigOption.Shared
  }

  const integrationSpecificSchema = isUpdate
    ? getDataCollectionUpdateFields(dataCollectionSpec)
    : getDataCollectionCreateFields(dataCollectionSpec)

  const fieldMappingTargetSchema = schemaWithTitle(
    integrationSpecificSchema ?? DEFAULT_SCHEMA,
    'Fields',
  )

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Fields</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={4}>
        <RadioGroup.Root
          value={selectedOption}
          onValueChange={handleSelectOption}
          gap={1}
        >
          <RadioGroup.Item value={ExportFieldMappingUnitConfigOption.Custom}>
            Provide fields inline
          </RadioGroup.Item>
          <RadioGroup.Item value={ExportFieldMappingUnitConfigOption.Shared}>
            Use shared field mapping config
          </RadioGroup.Item>
        </RadioGroup.Root>

        {selectedOption === ExportFieldMappingUnitConfigOption.Shared && (
          <SharedExportFieldMapping />
        )}

        {selectedOption === ExportFieldMappingUnitConfigOption.Custom && (
          <VStack alignItems={'stretch'} gap={2}>
            <Text m={0}>
              These values will be used by default when the integration is
              enabled. They can be changed later in the User Interface.
            </Text>
            {fieldMappingTargetSchema && variablesSchema?.properties?.input && (
              <GenerateFieldMappingButton
                fromSchema={variablesSchema}
                toSchema={fieldMappingTargetSchema}
                onGenerate={handleMappingChange}
              />
            )}

            <DataBuilderForm
              onChange={handleMappingChange}
              field={makeDataField({
                schema: fieldMappingTargetSchema,
                value: fieldMappingConfig.defaultValue,
                variablesSchema: variablesSchema,
              })}
              editableVariablesSchemaLocators={editableVariablesSchemaLocators}
              onAddVariable={handleAddVariable}
            />
          </VStack>
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
