import { useState } from 'react'
import { CreateDataSourceRequest, DataSource } from '@integration-app/sdk'
import { StepSelectUdm } from './StepSelectUdm'
import { StepCreateDataSource } from './StepCreateDataSource'
import { DataSourceType } from './index'

type CreateFlowProps = {
  title?: string
  dataSourceType: DataSourceType
  integrationId?: string
  readOnlyIntegration?: boolean
  onBack?: () => void
  onCreate: (dataSource: DataSource) => void
}

export function StepCreateFlow({ dataSourceType, ...props }: CreateFlowProps) {
  return dataSourceType === 'universal' ? (
    <CreateUniversalDataSource {...props} />
  ) : (
    <CreateIntegrationSpecificDataSource {...props} />
  )
}

export function CreateUniversalDataSource({
  title,
  onCreate,
}: Omit<
  CreateFlowProps,
  'dataSourceType' | 'integrationId' | 'readOnlyIntegration'
>) {
  const [udmSelectionComplete, setUdmSelectionComplete] = useState(false)
  const [draftData, setDraftData] =
    useState<Pick<CreateDataSourceRequest, 'name' | 'udm' | 'key'>>()

  function handleOnBackFromUdmSelectionStep() {
    setUdmSelectionComplete(false)
  }

  function handleOnSelectUdm(item) {
    setDraftData(item)
    setUdmSelectionComplete(true)
  }

  if (!udmSelectionComplete) {
    return (
      <StepSelectUdm
        title={title}
        selectedItem={draftData}
        onItemSelect={handleOnSelectUdm}
      />
    )
  }

  return (
    <StepCreateDataSource
      title={title}
      draftData={draftData}
      onCreate={onCreate}
      onBack={handleOnBackFromUdmSelectionStep}
      universal
    />
  )
}

export function CreateIntegrationSpecificDataSource({
  title,
  integrationId,
  readOnlyIntegration,
  onBack,
  onCreate,
}: Omit<CreateFlowProps, 'dataSourceType'>) {
  return (
    <StepCreateDataSource
      title={title}
      integrationId={integrationId}
      readOnlyIntegration={readOnlyIntegration}
      onCreate={onCreate}
      onBack={onBack}
    />
  )
}
