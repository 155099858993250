import { ReactNode } from 'react'
import { Alert as UiKitAlert } from 'ui-kit/alert'
import { Icon } from 'ui-kit/icon'

function AlertRoot({
  children,
  icon,
  ...props
}: UiKitAlert.RootProps & {
  icon?: ReactNode
}) {
  return (
    <UiKitAlert.Root {...props}>
      {!!icon && (
        <UiKitAlert.Icon asChild>
          <Icon>{icon}</Icon>
        </UiKitAlert.Icon>
      )}
      <UiKitAlert.Content>{children}</UiKitAlert.Content>
    </UiKitAlert.Root>
  )
}

const Alert = {
  Root: AlertRoot,
  Title: UiKitAlert.Title,
  Description: UiKitAlert.Description,
}

export { Alert }
