import React, { useEffect, useMemo, useState } from 'react'
import ReactFlow, {
  Background,
  CoordinateExtent,
  Edge,
  EdgeTypes,
  getNodesBounds,
  Node,
  NodeTypes,
  useReactFlow,
} from 'reactflow'
import { buildElements } from './elements/build-elements'
import { Flow, FlowInstance } from '@integration-app/sdk'
import { NODE_WIDTH } from './elements/common'

import 'reactflow/dist/base.css'
import './styles.module.css'
import { autoLayout } from './new-layout'
import { GraphNodeType } from './elements'

export const MAX_ZOOM = 1
export const MIN_ZOOM = 0.3
export const DEFAULT_ZOOM = 1

export function Graph({
  flowNodes,
  onPaneClick,
  nodeTypes,
  edgeTypes,
  usePlaceholders = false,
  fitOnNodesChange = false,
  withBackground = true,
}: {
  nodeTypes: NodeTypes
  edgeTypes: EdgeTypes
  flowNodes: Flow['nodes'] | FlowInstance['nodes']
  usePlaceholders?: boolean
  fitOnNodesChange?: boolean
  onPaneClick?(): void
  withBackground?: boolean
}) {
  const { fitBounds } = useReactFlow()

  const [fitted, setFitted] = useState(false)

  const { edges, nodes } = useBuildGraphElements(
    flowNodes || {},
    usePlaceholders,
  )

  const { rect, translateExtent } = useTranslateExtent(nodes, edges)

  function handlePaneClick() {
    onPaneClick?.()
  }

  useEffect(() => {
    // fit once there is more than one node because the first node is the root (virtual) node.

    const hasNodes = nodes?.length > 1

    if (hasNodes && !fitted) {
      fitBounds(rect)
      setFitted(true)
    }
  }, [nodes?.length])

  function handleOnNodesChange() {
    if (fitOnNodesChange) fitBounds(rect)
  }

  return (
    <ReactFlow
      minZoom={MIN_ZOOM}
      maxZoom={MAX_ZOOM}
      defaultViewport={{ x: 0, y: 0, zoom: DEFAULT_ZOOM }}
      nodesConnectable={false}
      panOnScroll
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      translateExtent={translateExtent}
      onPaneClick={handlePaneClick}
      onNodesChange={handleOnNodesChange}
    >
      {withBackground ? <Background gap={15} size={1} /> : <div />}
    </ReactFlow>
  )
}

/**
 * Builds nodes and edges for the graph
 */
function useBuildGraphElements(
  flowNodes: Flow['nodes'],
  withPlaceholders: boolean,
) {
  const [edges, setEdges] = useState<Edge[]>([])
  const [nodes, setNodes] = useState<Node[]>([])

  const renderDependencies = JSON.stringify(flowNodes)

  async function rebuild() {
    const { nodes: newNodes, edges: newEdges } = buildElements(
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2345): Argument of type 'Record<string, FlowNode> | undef... Remove this comment to see the full error message
      flowNodes,
      withPlaceholders,
    )

    autoLayout(newNodes, newEdges)

    setNodes(newNodes)
    setEdges(newEdges)
  }

  // rebuild nodes and edges once flow changes
  useEffect(() => {
    rebuild().catch(console.error)
  }, [renderDependencies])

  // cleanup
  useEffect(() => {
    return () => {
      setNodes([])
      setEdges([])
    }
  }, [])

  return {
    nodes,
    edges,
  }
}

/**
 * This hooks calculates the extent of the graph and the function to fit the graph to the screen
 */
function useTranslateExtent(nodes: Node[], edges: Edge[]) {
  const rect = useMemo(() => {
    return getNodesBounds(
      nodes.filter((node) => {
        return node.type !== GraphNodeType.Root // exclude root node
      }),
    )
  }, [JSON.stringify(nodes), JSON.stringify(edges)])

  const yPadding = 300
  const xPadding = 400 + NODE_WIDTH / 2 // NOTE: adding half of the node width to the padding because we excluded right trigger

  const translateExtent: CoordinateExtent = [
    [rect.x - xPadding, rect.y - yPadding], // [x0, y0]
    [rect.x + rect.width + xPadding, rect.y + rect.height + yPadding], // [x1, y1]
  ]

  return {
    translateExtent,
    rect,
  }
}
