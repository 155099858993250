import { EdgeProps } from 'reactflow'
import clsx from 'utils/clsx'
import classes from './Edge.module.css'
import { useEdgePath } from './useEdgeProps'
import { LinkNameOnEdgeCenter } from './LinkNameOnEdgeCenter'
import { FlowNodeLink } from '@integration-app/sdk'

export interface EdgeWithLinkData {
  link?: FlowNodeLink
  index?: number
}

export function EdgeWithLink(props: EdgeProps<EdgeWithLinkData>) {
  const { id, markerEnd, style = {}, data, source, targetX, targetY } = props

  const path = useEdgePath(props)

  return (
    <g>
      <path
        id={id}
        d={path}
        markerEnd={markerEnd}
        className={clsx(classes.staticEdgeLine, 'react-flow__edge-path')}
        style={{
          ...style,
        }}
      />

      <LinkNameOnEdgeCenter
        x={targetX}
        y={targetY - 40}
        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error TS(2322): Type 'FlowNodeLink | undefined' is not assignable ... Remove this comment to see the full error message
        link={data?.link}
        nodeKey={source}
      />
    </g>
  )
}
