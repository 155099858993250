import { Handle, HandleType, Position } from 'reactflow'
import classes from './Handle.module.css'
import clsx from 'utils/clsx'

const customHandle = (type: HandleType) => () => {
  const position = type === 'target' ? Position.Top : Position.Bottom

  return (
    <Handle type={type} position={position} className={clsx(classes.handle)} />
  )
}

export const TargetHandle = customHandle('target')
export const SourceHandle = customHandle('source')
