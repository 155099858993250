import { Popup } from '../index'
import { ComponentProps, ReactNode } from 'react'

function PopupRoot({ children, ...props }: ComponentProps<typeof Popup.Root>) {
  return (
    <Popup.Root lazyMount unmountOnExit {...props}>
      {children}
    </Popup.Root>
  )
}

function PopupHeader({
  children,
  ...props
}: ComponentProps<typeof Popup.Header>) {
  return (
    <Popup.Header
      display='flex'
      flexDirection='column'
      gap='2'
      paddingBlock='4'
      borderBlockEndWidth='1'
      borderBlockEndColor='border.subtle'
      {...props}
    >
      {children}
    </Popup.Header>
  )
}

function PopupFooter({
  children,
  ...props
}: ComponentProps<typeof Popup.Footer>) {
  return (
    <Popup.Footer
      paddingBlock='4'
      borderBlockStartWidth='1'
      borderBlockStartColor='border.subtle'
      {...props}
    >
      {children}
    </Popup.Footer>
  )
}

function PopupTitle({
  children,
  leftSlot,
  ...props
}: ComponentProps<typeof Popup.Title> & {
  leftSlot?: ReactNode
}) {
  return (
    <Popup.Title display='flex' alignItems='center' gap='2' {...props}>
      {leftSlot}
      {children}
    </Popup.Title>
  )
}

const CreateSmthPopup = {
  ...Popup,
  Root: PopupRoot,
  Header: PopupHeader,
  Footer: PopupFooter,
  Title: PopupTitle,
}
export { CreateSmthPopup }
