import { NodeProps } from 'reactflow'
import { TargetHandle } from 'components/FlowBuilder/Graph/elements/Handle/Handle'
import { PlaceholderNodeBody } from './PaceholderNodeBody/PlaceholderNodeBody'
import { AddNodeButton } from './AddNodeButton'
import { AddLinkButton } from './AddLinkButton'
import { NodeBodyContainer } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBodyContainer'
import { NodeStats } from '../RegularNode/RegularNode'

export interface RegularNodeOrLinkPlaceholderNodeData {
  parent?: string
  subgraphParent?: string
  linkIndex?: number
}

export function RegularNodeOrLinkPlaceholderNode(
  props: NodeProps<RegularNodeOrLinkPlaceholderNodeData>,
) {
  const { parent, linkIndex, subgraphParent } = props.data

  return (
    <NodeBodyContainer>
      <TargetHandle />

      <PlaceholderNodeBody>
        <AddNodeButton
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          parent={parent}
          child={''}
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          linkIndex={linkIndex}
          subgraphParent={subgraphParent}
        />
        {/* FIXME: strictNullCheck temporary fix */}
        {/* @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message*/}
        <AddLinkButton parent={parent} linkIndex={linkIndex} />
      </PlaceholderNodeBody>

      <NodeStats props={props} />
    </NodeBodyContainer>
  )
}
