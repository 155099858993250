import { FieldMapping } from '@integration-app/sdk'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { routeTo } from '../../routes-constants'
import { ConfigCard } from 'components/ConfigCard'
import { GoToElementLink } from '../../../../../components/GoToElemenLink'

export default function RelatedFieldMappingSection({
  fieldMapping,
}: {
  fieldMapping?: FieldMapping
}) {
  if (!fieldMapping) {
    return null
  }
  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        leftSlot={<SvgIcon type={SvgIconType.FieldMappingEntity} />}
      >
        <ConfigCard.Title>
          Field Mapping:
          <GoToElementLink to={routeTo.fieldMapping(fieldMapping)}>
            {fieldMapping.name}
          </GoToElementLink>
        </ConfigCard.Title>
      </ConfigCard.Header>
    </ConfigCard.Root>
  )
}
