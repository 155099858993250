import { FieldMappingDirection } from '@integration-app/sdk'
import { Box } from 'styled-system/jsx'
import { Text } from 'ui-kit/text'
import { useGenericConfig } from '../contexts/generic-config-context'
import { FieldMappingSelect } from '../FieldMappingSelect'

export function SharedImportFieldMapping() {
  const { patchFieldMappingConfig, fieldMappingConfig, integrationId } =
    useGenericConfig()

  return (
    <Box>
      <Text m={0} marginBlockEnd={2}>
        This field mapping will be synchronized between all actions and flows
        that use it.
      </Text>
      <FieldMappingSelect
        fieldMappingKey={fieldMappingConfig.key}
        integrationId={integrationId}
        direction={FieldMappingDirection.IMPORT}
        onChange={(key) => patchFieldMappingConfig({ key })}
      />
    </Box>
  )
}
